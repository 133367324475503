/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, SyntheticEvent, useEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

import { 
    FormControl,
    Checkbox, 
    InputLabel, MenuItem, 
    Select,
    TextField, 
    FormHelperText,
    Tab,
    Box
} from '@mui/material'
// import Select from "react-select";

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import { AlignmentList, DimensionsList, Element, ElementSetting, ParamSource, Source, TransitionList, emptyElement, secondsToHHmmss } from '../../../../redux/slices/content/contentModels'
import { setContentSectionSettings, setContentSource, setHoverLayerId, setHoverLayerOrder, setHoverLayerSourceId, setHoverSectionId} from '../../../../redux/slices/content/contentSlice'
import MaterialReactTable, { MRT_ColumnDef, MRT_RowSelectionState } from 'material-react-table'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input'

type Props = {  
    className?: string
}

const schema = yup.object().shape({
    transition: yup.string().min(1).max(100).required('Required'),
    transition_timer: yup.number().min(0.1, "Must be greater than 100 sec").required("Required")
})

// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerEditSection: FC<Props> = ({className}) => {

    const dispatch = useAppDispatch()  
    //-----------------------------------------
    const currPersistContent = useAppSelector(state => state.persistor._content.currContent)  
    const currSectId = useAppSelector(state => state.content.currSectionId)  
    // console.log('currPersistContent', currPersistContent)

    const [transitMode, setTransitMode] = useState('');
    // const [fixedTo, setFixedTo] = useState('full');
    // const [alignment, setAlignment] = useState('none');
    
    const [chkboxBgColor, setChkboxBgColor] = useState(false)
    const [bgColor, setBgColor] = useState("#00000000")
    const [sectOrder, setSectOrder] = useState(0)
    const [currSect, setCurrSect] = useState<Element>(emptyElement)
    // console.log('currSect', currSect)

    // console.log('===========================================')

    const currLayerId = useAppSelector(state => state.content.currLayerId)  
    // console.log('currLayerId in EditSection:',currLayerId)
    const currLayerOrder = useAppSelector(state => state.content.currLayerOrder)  
    // console.log('currLayerOrder in EditSection:',currLayerOrder)


    const dimensionKeys = Object.keys(DimensionsList)
    const alignKeys = Object.keys(AlignmentList).filter((key)=> key !== "none")

    const [rowSelection, setRowSelection] = useState({});
    // console.log('rowSelection', rowSelection)

    const [tabValue, setTabValue] = useState("layer"); 
    const handleChange = (event: SyntheticEvent<Element, Event>, newTabValue: any) => {
        setTabValue(newTabValue); 
    };  
    //-----------------------------------------    
    
    const { 
        register, 
        getValues,
        setValue,
        handleSubmit,
        // control,
        reset,
        watch,
        setError,
        formState:{
            errors,
            isValid,   
            isSubmitting,
        }
    } = useForm<ElementSetting>({
        resolver: yupResolver(schema),
        defaultValues:{            
            transition      : "fade",    
            transition_timer: 2,
        }
    }) 

    // --------------------------------
    const handleAddDuration = (second: number) =>{
        if (currSect.sources && currSect.sources.length > 0){
            
            const newSrcList = currSect.sources.map(src => {
                                        const prevDuration = (src.param_source[0].duration?src.param_source[0].duration:0)
                                        const newDuration  = prevDuration + (Object.keys(rowSelection).includes(src.source_list_id) ? second*1000 : 0)
                                        return  {   ...src,
                                                    param_source: [{    ...src.param_source[0],
                                                                        duration: (newDuration>0 ? newDuration: prevDuration)
                                                                    }]                             
                                                }
                                    })
            dispatch(setContentSource(newSrcList))
        }
    }

    // --------------------------------
    const handleChangeDimension = (value: string) =>{
        if (currSect.sources && currSect.sources.length > 0){
            
            const newSrcList = currSect.sources.map(src => {
                                        return  Object.keys(rowSelection).includes(src.source_list_id) 
                                                    ?   {   ...src,
                                                            param_source: [{    ...src.param_source[0],
                                                                                fill_to: value,
                                                                                alignment: (value !== "full") 
                                                                                                            ?   (src.param_source[0].alignment && src.param_source[0].alignment !== ""
                                                                                                                    ? src.param_source[0].alignment 
                                                                                                                    : "center"
                                                                                                                ) 
                                                                                                            : "" 
                                                                            }]
                                                        }
                                                    :   src
                                    })
            dispatch(setContentSource(newSrcList))
        }
    }

    // --------------------------------
    const handleChangeAlignment = (value: string) =>{
        if (currSect.sources && currSect.sources.length > 0){
            
            const newSrcList = currSect.sources.map(src => {
                                        return  Object.keys(rowSelection).includes(src.source_list_id) 
                                                    ?   {   ...src,
                                                            param_source: [{    ...src.param_source[0],
                                                                                alignment: AlignmentList[value][1]
                                                                            }]
                                                        }
                                                    :   src
                                    })
            dispatch(setContentSource(newSrcList))
            // setRowSelection({})
        }
    }

    const handleDelete = () =>{
        if (currSect.sources && currSect.sources.length > 0){
            // console.log("currSect: ", currSect)
            // console.log("(rowSelection): ", (rowSelection), rowSelection.length)
            // console.log("Object.keys(rowSelection): ", Object.keys(rowSelection))
            const newSrcList = currSect.sources.filter(src => (! Object.keys(rowSelection).includes(src.source_list_id)))
            dispatch(setContentSource(newSrcList))
            setRowSelection({})
        }
    }

    // --------------------------------
    
    const handleChangeBgColor = (hexColor: string, colors: MuiColorInputColors) =>{
        setBgColor(hexColor)

        if (currSect.sources && currSect.sources.length > 0){
            
            const newSrcList = currSect.sources.map(src => {
                                        return  Object.keys(rowSelection).includes(src.source_list_id) 
                                                    ?   {   ...src,
                                                            param_source: [{    ...src.param_source[0],
                                                                                bg_color: hexColor
                                                                            }]
                                                        }
                                                    :   src
                                    })
            dispatch(setContentSource(newSrcList))
        }

        setChkboxBgColor(true)  //activate the checkbox
    }


    const handleUnsetBgColor = () =>{
        setBgColor("#00000000")

        if (currSect.sources && currSect.sources.length > 0){            
            const newSrcList = currSect.sources.map(src => {
                                        return  Object.keys(rowSelection).includes(src.source_list_id) 
                                                    ?   {   ...src,
                                                            param_source: [{    ...src.param_source[0],
                                                                                bg_color: "#00000000"
                                                                            }]
                                                        }
                                                    :   src
                                    })
            dispatch(setContentSource(newSrcList))
        }
    }


    const handleChangeTransition = (transMode="", transTimer=1) => {
        if (currSect){
            dispatch(setContentSectionSettings({
                ...currSect.element_setting, 
                transition: transMode,
                transition_timer : transTimer * 1000,
            }))
        }
    }

    //---------------------------------------------------- 
    const formSubmitHandler: SubmitHandler<ElementSetting> = async (sectSetting: ElementSetting) => {        

        try{
            //update the section setting of currSectId
            dispatch(setContentSectionSettings({
                ...sectSetting, 
                transition_timer : sectSetting.transition_timer * 1000,
            }))
            
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-right',
                iconColor: 'green',
                customClass: {
                  popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 2000,
                // timerProgressBar: true
            })
            await Toast.fire({
                icon: 'success',
                title: `Section setting <br/> has been succesfully updated.`,
            })

        }catch(error){
            Swal.fire(
                'Section: '+ currSectId,
                'There is something wrong during the update!',
                'error'
            )            
        }
    }
   
    // ---------------------------
    useEffect(() => { 
        let sectOrd = 0
        const selectedSect = currPersistContent.elements.find((sect, ind)=> {
            if (sect.element_id === currSectId){
                sectOrd = ind
                return true
            }else{
                return false
            }
        })

        setCurrSect(selectedSect? selectedSect: emptyElement)

        var tmpTransitionMode : string = ""
        var tmpTransitionTimer : number = 0
        // var tmpFixTo : string = "full"
        // var tmpAlignment : string = "none"

        if (selectedSect && selectedSect.element_setting){
            tmpTransitionMode   = selectedSect.element_setting.transition
            tmpTransitionTimer  = selectedSect.element_setting.transition_timer/1000.0
            // tmpFixTo            = selectedSect.element_setting.fill_to? selectedSect.element_setting.fill_to:""
            // tmpAlignment        = selectedSect.element_setting.alignment? selectedSect.element_setting.alignment:""
        }
        // if (tmpFixTo === "")    {   tmpFixTo = "full"   }
        // if (tmpAlignment === ""){   tmpAlignment = "none"   }
        // ---------------------------------
        
        reset({
            transition      : tmpTransitionMode,
            transition_timer: tmpTransitionTimer,  
            // fill_to        : tmpFixTo, 
            // alignment       : tmpAlignment         
        })
        setTransitMode(tmpTransitionMode)
        // setAlignment(tmpAlignment)
        setSectOrder(sectOrd)
        // setRowSelection([])
    }, [ currSectId, currPersistContent ])


    // useEffect(() => { 
    //     console.log("currLayerId in useEffect:", currLayerId)
    // }, [ currLayerId ])

    const sourceColumns = useMemo<MRT_ColumnDef<Source>[]>(
        () => [             
            // {
            //     accessorKey: 'source_list_id',
            //     header: '#',
            //     // size: 25,                
            //     // enableGrouping: true,
            //     // Header: ({ table }) => (
            //     //   <Checkbox
            //     //     checked={table.getIsAllRowsSelected()}
            //     //     indeterminate={table.getIsSomeRowsSelected()}
            //     //     onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
            //     //   />
            //     // ),
            //     muiSelectCheckboxProps: ({ row, table }) => ({
            //         checked: row.original.source_list_id === currLayerId,
            //     }), 
            //     Cell: ({ row }) => {
            //         // console.log("row.id:", row.original.source_list_id, "===", currLayerId)
                    
            //         // if (row.original.source_list_id == currLayerId){
            //         //     row.toggleSelected()
            //         // }
            //         return (
            //             <Checkbox
            //                 checked={row.getIsSelected()}
            //                 disabled={!row.getCanSelect()}
            //                 onChange={row.getToggleSelectedHandler()}
            //             />
            //         )
            //     },
            // },    
            {
                accessorKey: 'order',
                header: '#',
                size: 25,                
                enableGrouping: true,        
                // muiSelectCheckboxProps: ({ row, table }) => ({
                //     checked: row.original.source_list_id === currLayerId,
                // }),        
                Cell: ({ cell, row  }) => {
                    // console.log("row.id:", row.id, "===", currLayerId)                    
                    if (row.id == currLayerId){
                        row.toggleSelected()
                    }
                    // --------------------------------
                    return (
                        <div className='text-capitalize ps-5 py-0'>
                            {cell.getValue<string>()}
                        </div>
                    )
                }                
            },
            {
                accessorFn: (currRow) => {                    
                    return (secondsToHHmmss(currRow.param_source[0].duration? currRow.param_source[0].duration: 0) + "s")
                }, //accessorFn used to join multiple data into a single cell
                id: 'source',
                header: 'Slide',
                size: 25,
                enableGrouping: true,
                Cell: ({ cell, row  }) => {
                    return (
                        <div className="symbol symbol-25px symbol-2by3 me-2 mt-2 bg-hover-primary"
                            onMouseOver={()=> {
                                dispatch(setHoverSectionId(currSect.element_id))
                                dispatch(setHoverLayerId(row.original.source_list_id))                                
                                dispatch(setHoverLayerOrder(row.original.order))                      
                                dispatch(setHoverLayerSourceId(row.original.source_id))
                            }}
                        >
                            {   row.original.source && 
                                row.original.source.type 
                                ?   {
                                        'image' :  <div className="symbol-label rounded-0" style={{ backgroundImage: `url('${row.original.source && row.original.source.is_remote === 0 ? row.original.source.url_thumb : row.original.source.url}')`,}}>&nbsp;</div>,
                                        'video' :  <div className="symbol-label rounded-0" style={{ backgroundImage: `url('${row.original.source && row.original.source.is_remote === 0 ? row.original.source.url_thumb : row.original.source.url}')`,}}>&nbsp;</div>,
                                        "audio"   : <div className='symbol-label rounded-0'><i className='fa fa-music svg-icon-3x'></i></div>,
                                        "pdf"   : <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-3x '/>                                        
                                    }[row.original.source.type]
                                : row.original.param_source[0].type === "web"
                                    ? <div className="symbol-label rounded-0 bg-light-primary"><i className="bi bi-globe text-primary fs-8 mx-3"></i></div>
                                    : row.original.param_source[0].text && <div className="symbol-label rounded-0"><KTSVG path='/media/icons/duotune/art/art001.svg' className='svg-icon-8 mx-3'/> </div>
                            }
                        
                            {/* icon bottom-right */}
                            {/* ----------------- */}
                            <div className="position-absolute top-50 start-75 opacity-100 ps-1">
                            { row.original.source && 
                                row.original.source.type && {                            
                                                    // 'image' :  <KTSVG path='/media/icons/duotune/general/gen006.svg' className='svg-icon-7'/>,
                                                    'video' :  <i className="fa fa-video-camera text-info shadow-sm fs-9"></i>,
                                                    // 'other' :  <KTSVG path='/media/icons/duotune/general/gen046.svg' className='svg-icon-8'/>,                              
                                                }[row.original.source.type]
                                                // : src.param_source[0].type === "web"
                                                //     ? <KTSVG path='/media/icons/duotune/coding/cod007.svg' className='svg-icon-8'/>
                                                //     : src.param_source[0].text && <i className='bi bi-fonts text-gray-400 shadow-sm fs-9'></i>
                                                //     // 'campaign': <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-8'/>,
                                                
                            }                          
                            </div>
                        </div>                   
                    )
                }
            },
            {
                accessorKey: 'param_source',
                header: 'Settings',
                size: 25,
                // enableGrouping: true,
                Cell: ({ cell, row  }) => {
                    const paramSrc = (cell.getValue<ParamSource[]>())[0]
                    // console.log("row.original: ", row.original)
                    // -----------------------------------
                    return (
                        <div className='text-capitalize ps-5 py-0'>
                            {paramSrc.bg_color &&
                                <i className={`bi bi-back ${paramSrc.bg_color} tooltip-bottom me-2`}
                                    style={{color : `${paramSrc.bg_color}`}}                                
                                    data-tooltip-content={`${paramSrc.bg_color}`}
                                ></i>
                            }
                            {(paramSrc.fill_to && paramSrc.fill_to !== "")
                                ?   <i className={`w-25px ${DimensionsList[paramSrc.fill_to][0]} tooltip-bottom me-2`}
                                        data-tooltip-content={`${DimensionsList[paramSrc.fill_to][1]}`}
                                    ></i>
                                :   <i className={`w-25px ${DimensionsList["full"][0]} tooltip-bottom me-2`}
                                        data-tooltip-content={`${DimensionsList["full"][1]}`}
                                    ></i>
                            }
                            {(paramSrc.alignment && paramSrc.alignment !== "") &&
                                    <i className={`w-25px ${AlignmentList[paramSrc.alignment][0]} tooltip-bottom`}
                                        data-tooltip-content={`Align ${AlignmentList[paramSrc.alignment][1]}`}
                                    ></i>
                                // :   <i className={`w-25px ${AlignmentList["topLeft"][0]} tooltip-bottom me-2`}
                                //         data-tooltip-content={`Align ${AlignmentList["topLeft"][1]}`}
                                //     ></i>
                            }
                            
                        </div>
                    )
                }
                
            },             
            {
                accessorFn: (currRow) => {      
                    if (currRow.param_source[0].duration && currRow.param_source[0].duration > 0 ){              
                        return (secondsToHHmmss(currRow.param_source[0].duration) + "s")
                    }else{
                        return ""
                    }
                }, //accessorFn used to join multiple data into a single cell
                id: 'duration',
                header: 'Duration',
                size: 25,
                enableGrouping: true,
                Cell: ({ cell, row  }) => {
                return (
                    <div className='text-capitalize ps-5 py-0'>
                    {cell.getValue<string>()}
                    </div>
                )
                }
                
            },      
        ],
        [currSect.sources],
    );

    
    //----------------------------------------------------
    return (
        <>  
            <div
                id="kt_drawer_edit_section"
                data-kt-drawer-toggle={`#kt_drawer_edit_section_button`}
                data-kt-drawer-close= {`#kt_drawer_edit_section_close`}

                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className}`}
                // data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                // data-bs-backdrop="static" 
                aria-labelledby="staticBackdropLabel"
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
            >
                <div className="offcanvas-header bg-gradient-primary">
                    <h5 className="offcanvas-title text-white" id="staticBackdropLabel">
                        <KTSVG
                            path={`/media/icons/duotune/art/art005.svg`}
                            className={`svg-icon-2 svg-icon-white me-2 mt-n2 d-inline`}
                        />
                        Edit Section '{(sectOrder+1).toString().padStart(2, '0')}'
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" 
                    
                    ></button>                        
                </div>
                <div className="offcanvas-body text-start">                    
                    <form id="frm-edit-layer" className='form mt-0'  
                        onSubmit={handleSubmit(formSubmitHandler)}
                    >   
                        <TabContext value={tabValue}> 
                            <Box sx={{ borderBottom: 1, borderColor: "#E4E6EF" }}> 
                                <TabList 
                                    // onChange={handleChange}
                                    onChange={(e, newTabValue) => {
                                        setTabValue(newTabValue)
                                    }}

                                    value={tabValue}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs"
                                    selectionFollowsFocus
                                    style={{padding: "0"}}
                                > 
                                    <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "100px"}}   label="Slide Settings"   value="layer" /> 
                                    <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "100px"}}   label="Transition"       value="settings" /> 
                                </TabList> 
                            </Box> 
                             
                            <TabPanel value="layer" className='p-1 py-5 '>
                                <div className="w-100 pb-5 container">
                                    <div className="mb-8">
                                        <div className='d-flex justify-content-between'>
                                            <div className='accordion' id='kt_accordion_advance_setting'
                                                style={{border: "0px !important"}}
                                            >
                                                <div className='accordion-item border border-0'>
                                                    <h2 className='accordion-header' id='kt_accordion_advance_setting_header_1'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-sm btn-link fw-bold collapsed dropdown-toggle'                                                            
                                                            data-bs-toggle='collapse'
                                                            data-bs-target='#kt_accordion_advance_setting_body_1'
                                                            aria-expanded='false'
                                                            aria-controls='kt_accordion_advance_setting_body_1'
                                                        >
                                                            Advanced
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id='kt_accordion_advance_setting_body_1'
                                                        className='accordion-collapse collapse p-0'
                                                        aria-labelledby='kt_accordion_advance_setting_header_1'
                                                        data-bs-parent='#kt_accordion_advance_setting'
                                                    >
                                                        <div className='accordion-body p-0'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">                                                
                                                                    <button type="button" className='btn btn-secondary btn-sm py-1 ps-3 pe-1 tooltip-bottom'
                                                                        data-tooltip-content={DimensionsList[dimensionKeys[0]][1]}
                                                                        disabled={Object.keys(rowSelection).length <=0}
                                                                        onClick={()=> handleChangeDimension(dimensionKeys[0])}
                                                                    >
                                                                        <i className={`${DimensionsList[dimensionKeys[0]][0]}`}></i>
                                                                    </button>
                                                                    
                                                                    <button type="button" className="btn btn-secondary rounded-end py-1 ps-2 pe-1 tooltip-bottom" data-bs-toggle="dropdown" aria-expanded="false"
                                                                        data-tooltip-content={DimensionsList[dimensionKeys[1]][1]}
                                                                        disabled={Object.keys(rowSelection).length <=0}
                                                                        onClick={()=> {
                                                                            handleChangeDimension(dimensionKeys[1])
                                                                        }}
                                                                    >
                                                                        <i className={`${DimensionsList[dimensionKeys[1]][0]}`}></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu p-0 mt-n2 ms-2" 
                                                                        style={{
                                                                            zIndex: "3050",
                                                                            minWidth: "50px",
                                                                            backgroundColor: "#ffffffee"
                                                                        }}
                                                                    >
                                                                        <div className='p-2'> 
                                                                            <div className="btn-group-vertical">
                                                                                {/* {(currSect.w >= currSect.h ? [1]:[0,1,2]).map((i, ind)=> {                                                     */}
                                                                                {(currSect.type === "text"? [0,1,2] : (currSect.w >= currSect.h ? [1]:[0,1,2])).map((i, ind)=> {                                                    
                                                                                    return (
                                                                                        <div key={`align-${ind}`} className="btn-group btn-group-sm" role="group" aria-label="Large button group">                                                    
                                                                                            {
                                                                                                      
                                                                                            (currSect.type === "text"?  alignKeys.slice(i*3,(i*3)+3)
                                                                                                                     :  (currSect.w >= currSect.h ? alignKeys.slice(i*3,(i*3)+3)
                                                                                                                                                  : alignKeys.slice((i*3)+1,(i*3)+2) 
                                                                                                                        )
                                                                                            )  
                                                                                            // alignKeys
                                                                                            .map((key, ind) => {                                                                                                
                                                                                                return (
                                                                                                    <button key={`align--${(i*3)+ind}`} type="button" 
                                                                                                        className='btn btn-secondary btn-sm py-1 ps-2 pe-1 tooltip-bottom'
                                                                                                        data-tooltip-content={AlignmentList[key][1]}
                                                                                                        disabled={Object.keys(rowSelection).length <=0}
                                                                                                        onClick={()=> {
                                                                                                            handleChangeAlignment(key)
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className={`${AlignmentList[key][0]}`}></i>
                                                                                                    </button>
                                                                                                )                                                       
                                                                                            })}
                                                                                        </div> 
                                                                                    )
                                                                                })}
                                                                                                                                
                                                                            </div>                                                
                                                                        </div>
                                                                    </ul>

                                                                    {/* <button type="button" className="btn btn-secondary rounded-end py-1 ps-2 pe-2 tooltip-bottom" data-bs-toggle="dropdown" aria-expanded="false"
                                                                        data-tooltip-content={DimensionsList[dimensionKeys[2]][1]}
                                                                        disabled={Object.keys(rowSelection).length <=0}
                                                                        onClick={()=> handleChangeDimension(dimensionKeys[2])}
                                                                    >
                                                                        <i className={`${DimensionsList[dimensionKeys[2]][0]}`}></i>
                                                                    </button>                                                
                                                                    <ul className="dropdown-menu p-0 shadow-sm mt-n2 ms-2" 
                                                                        style={{
                                                                            zIndex: "3050",
                                                                            minWidth: "50px",
                                                                            backgroundColor: "#ffffffee"
                                                                        }}
                                                                    >
                                                                        <div className='p-2'> 
                                                                            <div className="btn-group-vertical">
                                                                                {[0,1,2].map((i, ind)=> {                                                    
                                                                                    return (
                                                                                        <div key={`align---${ind}`} className="btn-group btn-group-sm" role="group" aria-label="Large button group">                                                    
                                                                                            {alignKeys.slice(i*3,(i*3)+3).map((key, ind) => {
                                                                                                return (
                                                                                                    <button key={`align--${(i*3)+ind}`} type="button" 
                                                                                                        className='btn btn-secondary btn-sm py-1 ps-2 pe-1 tooltip-bottom'
                                                                                                        data-tooltip-content={AlignmentList[key][1]}
                                                                                                        disabled={Object.keys(rowSelection).length <=0}
                                                                                                        onClick={()=> handleChangeAlignment(key)}
                                                                                                    >
                                                                                                        <i className={`${AlignmentList[key][0]}`}></i>
                                                                                                    </button>
                                                                                                )                                                       
                                                                                            })}
                                                                                        </div> 
                                                                                    )
                                                                                })}
                                                                                                                                
                                                                            </div>                                                
                                                                        </div>
                                                                    </ul> */}
                                                                </div>
                                                                
                                                                <div className='ms-3 d-flex'
                                                                    style={{width: (bgColor === "#00000000"? "130px":"155px")}}
                                                                >                                                                    
                                                                    <MuiColorInput 
                                                                        format="hex"  
                                                                        className='tooltip-bottom'
                                                                        data-tooltip-content='Background color'
                                                                        value={bgColor} 
                                                                        size='small'
                                                                        variant="outlined"
                                                                        inputProps={{ 
                                                                            // style: { 
                                                                            //     borderRadius: 0, 
                                                                            // } 
                                                                            sx: {
                                                                                borderRadius: 0,
                                                                                // '& > :not(style)': { m: 1, width: '25ch' },
                                                                            }
                                                                        }}

                                                                        disabled={Object.keys(rowSelection).length <=0}                                                    
                                                                        onChange={handleChangeBgColor}
                                                                    />

                                                                    <div 
                                                                        className='rounded-end me-n1'
                                                                        style={{
                                                                            height: "36px",
                                                                        }}
                                                                    >
                                                                        <Checkbox 
                                                                            checked={chkboxBgColor}
                                                                            className='tooltip-bottom'
                                                                            data-tooltip-content='Unset background color'
                                                                            disabled = {bgColor === "#00000000"}
                                                                            hidden   = {bgColor === "#00000000"}
                                                                            onChange={()=>{
                                                                                handleChangeBgColor("#00000000", {
                                                                                    hex: "#00000000",
                                                                                    hsl: "",
                                                                                    hsv: "",
                                                                                    rgb: "",
                                                                                    hex8: ""
                                                                                })
                                                                                setChkboxBgColor(! chkboxBgColor)
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}                                                                         
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div>
                                                <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">              
                                                    
                                                    <button type="button" className='btn btn-secondary btn-sm py-1 px-2 pe-0'
                                                        disabled={true}                                                    
                                                    >
                                                        <i className='bi bi-clock'></i>
                                                    </button>
                                                    <button type="button" className='btn btn-secondary btn-sm py-1 px-3 tooltip-bottom'
                                                        data-tooltip-content='Decrease 5 sec'
                                                        disabled={Object.keys(rowSelection).length <=0}
                                                        onClick={()=> handleAddDuration(-5)}
                                                    >
                                                        -5s
                                                    </button>
                                                    <button type="button" className='btn btn-secondary btn-sm py-1 px-3 tooltip-bottom'
                                                        data-tooltip-content='Increase 5 sec'
                                                        disabled={Object.keys(rowSelection).length <=0}
                                                        onClick={()=> handleAddDuration(5)}
                                                    >
                                                        +5s
                                                    </button>    
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='d-flex justify-content-end mt-3'>
                                            <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">              
                                                <button type="button" className='btn btn-secondary btn p-3 pe-2'
                                                    disabled={Object.keys(rowSelection).length <=0}
                                                    onClick={()=> handleDelete()}
                                                >
                                                    <i className='bi bi-trash'></i>
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="mb-4">
                                            <MaterialReactTable 
                                                columns={sourceColumns}
                                                data={currSect.sources? currSect.sources:[]}

                                                enableStickyHeader
                                                muiTableContainerProps={{ sx: { overflowX: 'hidden', maxHeight: '400px' } }}
                                                muiTablePaperProps={{
                                                    elevation: 0, //change the mui box shadow
                                                    //customize paper styles
                                                    sx: {
                                                    borderRadius: '1',
                                                    // backgroundColor: '#f6f6f6',
                                                    // border: '1px dashed #e0e0e0',              
                                                    },
                                                }}
                                                muiTableHeadCellProps={{ 
                                                    sx: { 
                                                    // align: 'right',
                                                    // fontWeight: 'bold', 
                                                    // textTransform: 'uppercase', 
                                                    // fontSize: '1.1rem',
                                                    // // color: '#ffffff',
                                                    // color: '#69025C',
                                                    // backgroundColor: '#f5f8fa',
                                                    borderStyle: 'solid',
                                                    borderBottom: '5px',
                                                    borderColor: '#9C258F',
                                                    // borderBottom: '5px solid #9C258F !important',
                                                    // paddingTop:'10px',
                                                    // paddingLeft:'10px',
                                                    marginLeft:'10px',

                                                    
                                                    '&:first-of-type': { 
                                                        borderTopLeftRadius: 5 
                                                    },
                                                    '&:last-child': { 
                                                        borderTopRightRadius: 5 
                                                    },
                                                    '&': {                
                                                        borderBottom: '5px !important',
                                                        fontWeight: 'bold !important',
                                                        paddingLeft:'10px',
                                                        marginLeft:'10px',

                                                        // backgroundColor: '#9C258F',  
                                                        // borderStyle: 'solid !important',
                                                        // borderWidth: '1px !important',
                                                        // borderColor: '#9C258F !important',
                                                        // borderBottom: '5px solid #9C258F !important',


                                                    },
                                                    }, 
                                                }}
                                                muiTableBodyProps={{
                                                    sx: {
                                                        padding: "0px",
                                                        // backgroundColor: '#ff1100',
                                                        // backgroundColor: '#9C258F',
                                                        // //stripe the rows, make odd rows a darker color              
                                                        // '& tr:nth-of-type(odd)': {
                                                        //   // backgroundColor: '#fcfcfc',                
                                                        //   backgroundColor: '#9C258F',                
                                                        // },
                                                        '& tr td:first-of-type': {
                                                            paddingLeft: '10px',
                                                            width: '20px'
                                                        },
                                                        '& tr:first-of-type td': {                
                                                            borderBottom: '1px solid #f6f6f6 !important',
                                                        },
                                                        '& tr:last-child td': {                
                                                            borderBottom: '1px solid #f6f6f6 !important',
                                                        },
                                                        '& tr:not(:last-child) td': {                
                                                            borderBottom: '1px solid #f6f6f6',
                                                        },
                                                        '& tr td': {                
                                                            // borderBottom: '0px',
                                                            paddingLeft: '0px',
                                                            // backgroundColor: '#f5f8fa',                

                                                        },
                                                    },
                                                }}
                                                
                                                
                                                
                                                // state={{ 
                                                //     isLoading: isLoadingDevice,
                                                // }}
                                                // editingMode="modal" //default

                                                enableColumnActions={false}
                                                enableColumnFilters={false}
                                                enableDensityToggle={false}
                                                enableFullScreenToggle={false}
                                                enableGlobalFilter={false}
                                                enablePagination={false}

                                                enableTopToolbar={false}
                                                enableBottomToolbar={false}
                                                initialState={{ 
                                                    columnVisibility: { order: false },

                                                    density: 'compact',
                                                    //   expanded: true, //expand all groups by default

                                                    // grouping: ['groupName'], //an array of columns to group by by default (can be multiple)
                                                    pagination: { pageIndex: 0, pageSize: 100 },
                                                    sorting: [{ id: 'order', desc: false }], //sort by state by default       
                                                }}

                                                enableRowSelection={true}
                                                getRowId={(row) => row.source_list_id} //give each row a more useful id
                                                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                                                state={{ rowSelection }} //pass our managed row selection state to the table to use
                                                // muiSelectCheckboxProps={({ row }) => {
                                                //     console.log("---------------------------------------------")
                                                //     console.log("row:", row)
                                                //     console.log("row.original.source_list_id:", row.original.source_list_id)
                                                //     console.log("currLayerId:", currLayerId)
                                                    
                                                //     // let checkFlag = false
                                                //     // if (! row.getIsSelected()){
                                                //     //     row.toggleSelected()
                                                //     //     checkFlag = true
                                                //     // }else{
                                                //     //     checkFlag = false
                                                //     // }
                                                //     // -----------------------------
                                                //     return ({
                                                //         // onClick: row.getToggleSelectedHandler(),
                                                //         // onClick: row.toggleSelected(),
                                                //         // onClick: console.log(row.original),
                                                //         // checked: row.original.source_list_id === currLayerId,
                                                //         checked: row.original.source_list_id === "D0C29PEQ6U",                                                        
                                                //         // checked: checkFlag,
                                                //         // checked: true
                                                //         // sx: { cursor: 'pointer' },
                                                //     })
                                                // }}
                                                // muiSelectCheckboxProps={({ row }) => ({
                                                //     checked: row.original.source_list_id == currLayerId,
                                                // })}
                                                muiTableBodyRowProps={({ row }) => {
                                                    console.log("---------------------------------------------")
                                                    console.log("row:", row)
                                                    console.log("row.original.source_list_id:", row.original.source_list_id)
                                                    console.log("currLayerId:", currLayerId)
                                                    // ---------------------------
                                                    return ({
                                                        onClick: row.getToggleSelectedHandler(),
                                                        // checked: row.original.source_list_id == currLayerId,
                                                        sx: { cursor: 'pointer' },
                                                    })
                                                }}

                                                // options={{
                                                //     selection: true,
                                                //     selectionProps: rowData => ({
                                                //       disabled: rowData.name === 'Mehmet',
                                                //       color: 'primary',
                                                //       checked: rowData.id === '1'
                                                //     })
                                                // }}
                                                // muiSelectCheckboxProps={{
                                                //     color: 'primary',                                                    
                                                // }}
                                                // positionActionsColumn="last"
                                                // enableRowActions
                                                // renderRowActions={({ row, table }) => {
                                                //     // ---------------------------------------------------
                                                //     return (
                                                //         <div className="d-flex flex-end">
                                                //             <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
                                                //                 Action
                                                                
                                                //             </div>                      
                                                //         </div>
                                                //     )
                                                // }} 
                                            
                                            />
                                    </div>
                                    
                                    {Object.keys(rowSelection).length <= 0 &&
                                        <div className='mb-4 text-muted fst-italic'>
                                            Note: Please select one slide at least
                                        </div>
                                    }
                                </div>
                            </TabPanel>
                            <TabPanel value="settings" className='p-1 py-5'>
                                <div className="w-100 pb-5">
                                    <div className='row'>                                    
                                        <div className="col-7" style={{paddingTop:"8px"}}>
                                            <FormControl fullWidth>
                                                <InputLabel id="select-transition-label">Transition</InputLabel>
                                                <Select 
                                                    {...register("transition")}
                                                    className='text-capitalize' 
                                                    labelId="select-transition-label"                                        
                                                    label="Transition"
                                                    size="small"
                                                    value={transitMode}
                                                    onChange={(e) => {
                                                        setTransitMode(e.target.value);
                                                        handleChangeTransition(e.target.value, getValues('transition_timer'))
                                                    }}
                                                    error={!! errors.transition}
                                                    MenuProps={{ disablePortal: true }}
                                                >        
                                                    <MenuItem key={0} value={0} disabled>Select Transition</MenuItem>
                                                    {Object.keys(TransitionList).map((key,ind) => {
                                                        return (
                                                            <MenuItem key={`trans_${ind}`} value={key} className='w-100 text-capitalize'>
                                                                <i className={`${TransitionList[key][1]} me-2`}></i>
                                                                {TransitionList[key][0]}
                                                            </MenuItem>
                                                        )}
                                                    )}                                        
                                                </Select>
                                                <FormHelperText>{`${errors.transition?.message ?? ''}`}</FormHelperText>
                                            </FormControl>
                                        </div>                            
                                        <div className="col-5">
                                            <TextField 
                                                {...register("transition_timer")} 
                                                type="number"
                                                label="Transition Timer (sec)" 
                                                fullWidth 
                                                size="small"
                                                margin='dense'
                                                variant='outlined' 
                                                onChange={(e) => {                                                    
                                                    handleChangeTransition(transitMode, parseInt(e.target.value))
                                                }}
                                                InputLabelProps={{ shrink: true }}                                    
                                                error={!! errors.transition_timer}
                                                helperText={`${errors.transition_timer?.message ?? ''}`}                                    
                                            />
                                        </div>
                                    </div>
                                </div>                                    
                            </TabPanel>
                        </TabContext>
                                              
                                                    
                        
                        
                        <button type="button" 
                            className="btn btn-icon btn-light position-absolute bottom-0 end-0 me-7 mb-8" 
                            data-bs-dismiss="offcanvas"
                            data-kt-drawer-dismiss="true"
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr001.svg'
                                className='svg-icon svg-icon-1x'
                            />
                        </button>
                            
                    </form>

                </div>
            </div>
        </>
    )
}

export {DrawerEditSection}