/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'

import { 
    FormControl,
    Checkbox, 
    InputLabel, MenuItem, 
    Select,
    TextField, 
    FormHelperText,
    FormGroup,
    FormControlLabel
} from '@mui/material'
// import Select from "react-select";

import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { DEFAULT_FONT_COLOR, DirectionTYPE, Element, emptyElement, emptyTextSrc, ParamSource, Source, TEXTSTYLE_LIST } from '../../../../redux/slices/content/contentModels';
import { useInView } from 'react-intersection-observer'
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input'
import WebFont from 'webfontloader'
import { useGetFontListQuery } from '../../../../redux/slices/content/apiContentSlice'
import { SubmitHandler, useForm } from 'react-hook-form'
import { setCheckSum, setContentSource, setContentSourceLayer, setLayerId } from '../../../../redux/slices/content/contentSlice'
import { DraggableText } from '../EditorComponents'
import styled from 'styled-components'
import { defaultTransparentGrids } from '../../../../redux/slices/media/mediaModels'


type Props = {
    className: string,
}

type TextProps = {
    text: string,  
}
  


const schema = yup.object().shape({  
    text: yup.string()
         .min(0, 'min. one characher')
        //  .required('Required')
})



const MuiColorInputStyled = styled(MuiColorInput)`
    & .MuiColorInput-AlphaSlider {
        margin-top: 10px;
    }
    & .MuiInputBase-input{
        display: block;
        width: 75px;
        text-transform: uppercase;
    }
    & .MuiOutlinedInput-notchedOutline {
        border: 1
    }
    & .MuiButton-text.MuiButton-textPrimary{        
        width: 30px;
        height: 30px;
        margin-left : -11px;
    }
`
// const MuiColorInputStyled = styled(MuiColorInput)`
//     & .MuiColorInput-AlphaSlider {
//         margin-top: 10px;
//     }
//     & .MuiInputBase-input{
//         display: none
//     }
//     & .MuiOutlinedInput-notchedOutline {
//         border: 0
//     }
//     & .MuiButton-text.MuiButton-textPrimary{
//         margin-top: 30px;
//         width: 30px;
//         height: 30px;
//     }
// `

// MuiFormControl-root MuiFormControl-fullWidth css-q8hpuo-MuiFormControl-root
// & .MuiButton-text, 
// & .MuiButton-root{
//     backgroundColor: "#ff0000";
// }  
// MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall MuiInputBase-inputAdornedStart css-1hg3vs0-MuiInputBase-input-MuiOutlinedInput-input
// MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiColorInput-Button  css-o9xkyi-MuiButtonBase-root-MuiButton-root


// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerTextEditor: FC<Props> = ({ className}) => {

    const dispatch = useAppDispatch()
    //-----------------------------------------
    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    //-----------------------------------------

    const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
    // console.log("currContentPersistor outside: ", currContentPersistor)    

    const { data: allFonts, isLoading: isLoadingAllFonts, isFetching: isFetchingAllFonts, isError } = useGetFontListQuery()
    // console.log("allFonts: ", allFonts)    

    const txtEditorCnt = useAppSelector(state => state.editor.textEditorCnt)  
    // console.log("txtEditorCnt in ModalTextEditor:", txtEditorCnt)  

    

       

    // const [fullScreenMode, setFullScreenMode] = useState(false)

    // const ratioW = 0.7
    // const ratioH = 0.9

    // const BORDERSIZE = 6  //6px
    // const [previewScreenW, previewScreenH] = currContentPersistor.width >= currContentPersistor.height 
    //                                             ? [Math.round(ratioW  * window.innerWidth), Math.round((9/16) * ratioW * window.innerWidth)]
    //                                             : [Math.round((9/16) * ratioH * window.innerHeight), Math.round(ratioH * window.innerHeight)]  
    // console.log("previewScreenW : ",previewScreenW)
    // console.log("previewScreenH : ",previewScreenH)



    const currSectId = useAppSelector(state => state.content.currSectionId)  
    const [currSect, setCurrSect] = useState<Element>(emptyElement)
    // console.log("currSect outside: ", currSect)
    const currLayerId = useAppSelector(state => state.content.currLayerId)      
    // console.log('currLayerId outside:',currLayerId)
    const [currSrc, setCurrSrc] = useState<Source>()
    // console.log('currSource outside:',currSrc)
    // console.log('currSource.param_source[0].text outside:', currSrc?.param_source[0].text)

    const chckSum = useAppSelector(state => state.persistor._content.checkSum)
    // console.log("chckSum: ", chckSum) 


    const currLayerOrder = useAppSelector(state => state.content.currLayerOrder)  
    // console.log('currLayerOrder outside:',currLayerOrder)


    const [selectedTxtStyle, setSelectedTxtStyle] = useState<string>("")

    const [txtColor, setTxtColor] = useState(DEFAULT_FONT_COLOR)  
    const [chkboxBgColor, setChkboxBgColor] = useState(false)
    const [bgColor, setBgColor] = useState("")
    const [fontSize, setFontSize] = useState(36)
    const [fontFam, setFontFam] = useState<string>("Roboto")
    const [isHTML, setIsHTML] = useState(false)
    const [isMarqueeText, setIsMarqueeText] = useState(false)


    const { 
        register, 
        handleSubmit,
        getValues,
        setValue,
        reset,
        watch,
        formState:{
            errors,
            isDirty,
            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful,
        }
    } = useForm<ParamSource>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues:{  

            text      : "",
            txtId     : "",
            fontcolor       : DEFAULT_FONT_COLOR,

            html            : false,        
            backgroundcolor : null,

            marquee         : false,
            direction       : "left",
            speed           : 50,  
            speed_x         : 50,
            speed_y         : 0,              
            
            fill_to         : "full",
            alignment       : "center",
            
            font            : "Roboto",
            fontitalic      : "",
            fontstyle       : "",
            fontweight      : "",
            fontdecoration  : "",
            fontdecorationcolor : "",
            hasshadow       : false,

            size            : 36,

            
            // "loop": 0,                   

            // // "shadows": [
            // //     {
            // //         "dx": 3,
            // //         "dy": 3,
            // //         "radius": 3,
            // //         "color": "#770F99FF"
            // //     },
            // //     {
            // //         "dx": 2,
            // //         "dy": 2,
            // //         "radius": 2,
            // //         "color": "#77FF0000"
            // //     }
            // // ], 
        },      
    }) 
    
    // console.log("watch:", watch())
    // console.log("watch(['text']):", watch(["text"]))


    // ---------------------------------------------
    // const handleChangeTxtTemplate = (txtStyle: ParamSource) => {
        
    //     let newParamSrc: ParamSource[] = [{
    //                                             delay: 0,
    //                                             duration: 10000,
    //                                             volume: null,
    //                                             bg_color: "#00000000",

    //                                             ...txtStyle     
    //                                         }]

    //     // -----------------------------------
    //     var newSource: Source = {
    //                                 source_list_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
    //                                 element_id    : Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
    //                                 source_id: '0',
    //                                 order:  0,
    //                                 duration_override: -1,    

    //                                 type: "text",
    //                                 source:  null,

    //                                 param_source : newParamSrc
    //                             }
    //     // console.log("newSource: ",newSource)
        
    //     var sourceList: Source[] = []
    //     sourceList = [newSource] //take only the last one

    //     dispatch(setContentSource(sourceList))
    // } 

    // -------------------------
    const handleChangeTxtColor = (hexColor: string, colors: MuiColorInputColors) =>{

        // const selectedColor = colors.hex
        const selectedColor = colors.hex8

        setTxtColor(selectedColor)
        setValue("fontcolor", selectedColor)        
        
        if (currSrc){
            var newSrc : Source = {
                            ...currSrc,
                            param_source:   [{  ...currSrc.param_source[0],
                                                fontcolor: selectedColor,
                                                fontdecorationcolor: selectedColor
                                            }]
                        }            
            dispatch(setContentSourceLayer(newSrc))
        }
    }

    // -------------------------
    const handleChangeBgColor = (hexColor: string, colors: MuiColorInputColors) =>{

        // const selectedColor = colors.hex
        const selectedColor = colors.hex8
        
        setBgColor(selectedColor)
        setValue("backgroundcolor", selectedColor)

        if (currSrc){
            var newSrc : Source = {
                            ...currSrc,
                            param_source:   [{  ...currSrc.param_source[0],
                                                backgroundcolor: selectedColor
                                            }]
                        }            
            dispatch(setContentSourceLayer(newSrc))
        }
    }

    
    // -------------------------
    const handleChangeFont = (fontName: string) =>{        
        setFontFam(""+ fontName)
        setValue('font', ""+ fontName)
        // trigger("font") //manually trigger the form or input validation

        if (currSrc){
            var newSrc : Source = {
                            ...currSrc,
                            param_source:   [{  ...currSrc.param_source[0],                                                
                                                font: fontName,
                                            }]
                        }            
            dispatch(setContentSourceLayer(newSrc))
        }
    }

    // -------------------------
    const handleChangeSize = (numb: number) =>{        
        setFontSize(numb)
        setValue('size', numb)        
        // trigger("size") //manually trigger the form or input validation

        if (currSrc){
            var newSrc : Source = {
                            ...currSrc,
                            param_source:   [{  ...currSrc.param_source[0],                                                
                                                size: numb
                                            }]
                        }            
            dispatch(setContentSourceLayer(newSrc))
        }
    }

    // -------------------------
    const handleChangeStyle = (styleName: string) =>{
        var newVal = ""
        var newUpdatedParamSrc : ParamSource = {}
        // ================================

        
        if (styleName === "fontweight"){
            newVal = (getValues("fontweight") === "bold" ? "": "bold")
            setValue("fontweight", newVal)
            newUpdatedParamSrc = {  fontweight : newVal }
        }else if (styleName === "fontitalic"){
            newVal = (getValues("fontitalic") === "italic" ? "": "italic")
            setValue("fontitalic", newVal)
            newUpdatedParamSrc = {  fontitalic : newVal }

        }else if (styleName === "fontdecoration"){
            newVal = (getValues("fontdecoration") === "underline" ? "": "underline")
            setValue("fontdecoration", newVal)
            newUpdatedParamSrc = {  fontdecoration : newVal }

        }else if (styleName === "fontdecorationOverline"){
            newVal = (getValues("fontdecoration") === "overline" ? "": "overline")
            setValue("fontdecoration", newVal)
            newUpdatedParamSrc = {  fontdecoration : newVal }
        }else if (styleName === "fontdecorationStrike"){
            newVal = (getValues("fontdecoration") === "lineThrough" ? "": "lineThrough")
            setValue("fontdecoration", newVal)
            newUpdatedParamSrc = {  fontdecoration : newVal }
        }


        if (currSrc){
            var newSrc : Source = {
                            ...currSrc,
                            param_source:   [{  ...currSrc.param_source[0],                                                
                                                ...newUpdatedParamSrc
                                            }]
                        }            
            dispatch(setContentSourceLayer(newSrc))
        }
    }

    // -------------------------
    const handleChangeDirection = (direction: DirectionTYPE) =>{
        setValue("direction", direction)

        if (currSrc){
            var newSrc : Source = {
                            ...currSrc,
                            param_source:   [{  ...currSrc.param_source[0],                                                
                                                direction : direction,
                                                remove_new_line : ['up','down'].includes(direction) ? false: true,
                                            }]
                        }            
            dispatch(setContentSourceLayer(newSrc))
        }
    }

    // -------------------------
    const handleChangeSpeed = (sped: number, isSpeedX= false, isSpeedY=false) =>{
        const valSpeed: number = parseInt(sped.toString())
        setValue("speed", valSpeed)
        setValue("speed_x", valSpeed)
        setValue("speed_y", valSpeed)

        if (currSrc){
            var newSrc : Source = {
                            ...currSrc,
                            param_source:   [{  ...currSrc.param_source[0],                                                
                                                speed   : valSpeed,
                                                speed_x : valSpeed,
                                                speed_y : valSpeed
                                            }]
                        }     
            dispatch(setContentSourceLayer(newSrc))
        }
    }
    
    // ----------------------------------------
    const handleReset = () => {
        // console.log("--------------------------handle reset")
        setSelectedTxtStyle("")
        reset({
            ...emptyTextSrc.param_source[0]
        })
        
        setTxtColor(DEFAULT_FONT_COLOR)
        setBgColor("")
        setChkboxBgColor(false)
        setFontFam("Roboto")
        setFontSize(36)
        setIsHTML(false)
        setIsMarqueeText(false)
    }

    // --------------------------------------------
    const formSubmitHandler: SubmitHandler<ParamSource> = async (textEle: ParamSource) => {  

        // var arrItems: any[] = []
        // ----------------------------------------
        // console.log("====Submit========================================== ")
        // console.log("textEle: ", textEle)
        // console.log("watch in submit: ", watch())

        // correcting the format of text content
        // --------------------------------------
        var tmpTextContent = textEle.text    
        if (tmpTextContent){
            if (tmpTextContent.substring(0,3) === "<p>"){
                tmpTextContent = tmpTextContent.substring(3, tmpTextContent.length - 5)
            }

            //remove all html tags for normal text and marquee text
            // if (textEle.marquee || !textEle.html){
            //   tmpTextContent = tmpTextContent.replace(/<\/[^>]+(>|$)/g, "")
            //   // console.log("tmpTextContent============: ", tmpTextContent)
            // }
        }

        if (currSrc){
            var newSrc : Source = {
                            ...currSrc,
                            param_source: [{...textEle}]
                        }
            dispatch(setContentSourceLayer(newSrc))
        }    
    }


    useEffect(() => {
        WebFont.load({
            google: {
                families: [
                'Bayon',
                'Bungee Shade',
                'Eater',
                'Honk',
                'Jura',
                'Monoton',
                'Open Sans', 
                'Parisienne',
                'Roboto', 
                'TitanOne',          
                ]
            }
        });
    }, []);

    // -----------------------------
    // useEffect(() => { 
    //   const selectedSect = currContentPersistor.elements.find((sect, ind)=> sect.element_id === currSectId)    
    //   setCurrSect(selectedSect? selectedSect: emptyElement)

    //   if (selectedSect && selectedSect.sources.length > 0){
    //     const tmp: ParamSource = selectedSect.sources[0].param_source[0]
    //     if (tmp){        
    //       reset({
    //         txtId: selectedTxtStyle,
    //         text: txtEditorCnt,
    //         fontcolor       : tmp.fontcolor? tmp.fontcolor : "#000000",

    //         html            : tmp.html? tmp.html : false,        
    //         backgroundcolor : tmp.backgroundcolor? tmp.backgroundcolor : "",

    //         marquee         : tmp.marquee ? tmp.marquee : false,
    //         direction       : tmp.direction? tmp.direction : "left",                
    //         alignment       : "",
    //       })
    //     }
    //   }else{
    //     reset({
    //       txtId: selectedTxtStyle,
    //       text: txtEditorCnt,        
    //       fontcolor       : "#000000",

    //       html            : false,        
    //       backgroundcolor : null,

    //       marquee         : false,
    //       direction       : "left",                
    //       alignment       : "",
    //     }) 
    //   } 
    // },[txtEditorCnt])


      


    // -----------------------------
    useEffect(() => { 
        const selectedSect = currContentPersistor.elements.find((sect, ind)=> sect.element_id === currSectId)
        setCurrSect(selectedSect? selectedSect: emptyElement)

        // console.log("-----------------------------------")
        // console.log("selectedSect:", selectedSect)
        
        const selectedSrc : Source | undefined = selectedSect?.sources.find((src)=> 
                                                    (src.source_list_id === currLayerId) && 
                                                    ((currLayerOrder <= 0) || (currLayerOrder > 0 && src.order === currLayerOrder))
                                                )
        setCurrSrc(selectedSrc? selectedSrc : emptyTextSrc)
        // console.log("selectedSource:", selectedSrc)
        // console.log("chckSum:", chckSum)


        if (chckSum === 0){ // Add new text        
            var newTxtSrc : Source ={
                                        ...emptyTextSrc,
                                        source_list_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                                        element_id    : Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                                        order: selectedSect? selectedSect.sources.length + 1 : 0,
                                        param_source: [{
                                                            ...emptyTextSrc.param_source[0],
                                                            text: "",

                                                        }]
                                    }
            var sourceList: Source[] = selectedSect? selectedSect.sources : []
            sourceList = [
                            newTxtSrc,
                            ...sourceList
                        ]            
            // console.log("new sourceList:", sourceList)

            dispatch(setContentSource(sourceList))
            dispatch(setLayerId(newTxtSrc.source_list_id))
            dispatch(setCheckSum(1))

            handleReset()
        }else{
            // Edit text
            if (selectedSect && selectedSect.sources.length > 0){

                if (selectedSrc){
                    const tmp: ParamSource = selectedSrc.param_source[0]

                    if (tmp){
                        setSelectedTxtStyle(tmp.txtId? tmp.txtId : "")
                        reset({
                            ...tmp,

                            txtId           : tmp.txtId? tmp.txtId: "",
                            text            : tmp.text? tmp.text : "",
                            font            : tmp.font? tmp.font : "Roboto",
                            fontcolor       : tmp.fontcolor? tmp.fontcolor : DEFAULT_FONT_COLOR,
                            fontitalic      : tmp.fontitalic? tmp.fontitalic : "",
                            fontstyle       : tmp.fontstyle? tmp.fontstyle : "",
                            fontweight      : tmp.fontweight? tmp.fontweight : "",
                            fontdecoration  : tmp.fontdecoration? tmp.fontdecoration : "",

                            size            : tmp.size ? tmp.size : 36,

                            html            : tmp.html? tmp.html : false,        
                            backgroundcolor : tmp.backgroundcolor? tmp.backgroundcolor : "",

                            marquee         : tmp.marquee ? tmp.marquee : false,
                            direction       : tmp.direction? tmp.direction : "left",                
                            speed           : tmp.speed? parseInt(tmp.speed.toString()) : 50,
                            speed_x         : tmp.speed_x? tmp.speed_x : 50,
                            speed_y         : tmp.speed_y? tmp.speed_y : 0,

                            alignment       : tmp.alignment? tmp.alignment : "",
                        })

                        setTxtColor(tmp.fontcolor? tmp.fontcolor : "")
                        setBgColor (tmp.backgroundcolor? tmp.backgroundcolor : "")
                        setChkboxBgColor(tmp.backgroundcolor && tmp.backgroundcolor !== ""? true: false)
                        setFontFam(tmp.font? tmp.font : "Roboto")                        
                        setFontSize(tmp.size ? tmp.size : 36)
                        setIsHTML(tmp.html? tmp.html : false)
                        setIsMarqueeText(tmp.marquee ? tmp.marquee : false)
                    }
                }else{
                    handleReset()
                }
            }else{
                handleReset()
            }
        }
    }, [ currContentPersistor, currSectId, , chckSum, currLayerId, currLayerOrder]) 


    //----------------------------------------------------
    return (
            <div
                id="kt_drawer_text_editor"
                data-kt-drawer-toggle={`#kt_drawer_text_editor_button`}
                data-kt-drawer-close= {`#kt_drawer_text_editor_close`}

                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className} `}
                // w-sm-300px w-md-300px w-lg-300px w-xl-500px
                // data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                // data-bs-backdrop="static" 
                aria-labelledby="staticBackdropLabel"
                data-kt-drawer-width="{default:'300px', 'md': '500px', 'lg': '800px}"
                
                ref={ref}

                // onMouseOut={()=> {
                //     // setIsMuted(false)
                //     // setTimeout(() => {
                //     //     setIsMuted(true)                
                //     // }, 100);
                //     let vid =  document.getElementById("videoLayer") as HTMLVideoElement ;
                //     if (vid){
                //         vid.pause();
                //         // vid.currentTime = 0;
                //         vid.muted= true;
                //     }
                // }}
            >
                <div className="offcanvas-header bg-gradient-primary">
                    <h5 className="offcanvas-title text-white" id="staticBackdropLabel">                       
                        <KTSVG path='/media/icons/duotune/coding/cod003.svg' className='svg-icon-2x svg-icon-white me-2 mt-n2 d-inline' />   
                        {chckSum === 0 ? "Add new text":"Edit text"}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"> 
                    </button>
                </div>
                <div className="offcanvas-body text-start">
                    {inView &&  
                        <form id="frm-text-editor" className='form'  
                            onSubmit={handleSubmit(formSubmitHandler)}
                            onChange={handleSubmit(formSubmitHandler)}
                        >                                     
                            <div className='mb-2 d-flex justify-content-between'>
                                {/* List of text templates */}
                                {/* <div className='container px-5 mb-4'>
                                    <div className='row'>        
                                        {TEXTSTYLE_LIST.map((txtStyle, ind)=> {
                                        return (
                                            <div key={`txt-style-${ind}`} 
                                            className='col-6 col-xl-3 w-25 mt-5 pe-auto'
                                            onClick={() => {
                                                handleChangeTxtTemplate(txtStyle)
                                                setSelectedTxtStyle(txtStyle.txtId? txtStyle.txtId: "")
                                            }}
                                            >
                                            <DraggableText className='mb-4 border-white' item= {txtStyle} 
                                                draggable  = {false}

                                                selectable = {true}
                                                selected   = {selectedTxtStyle === txtStyle.txtId}                
                                            />
                                            </div>
                                        )
                                        })}
                                    </div>
                                </div>   */}
                                {/* {selectedTxtStyle === "" &&
                                <div className='px-5 mb-4'>
                                    <img src="/media/_editor/select-text-template-02.png" className='w-75 w-lg-50 w-xl-50 ms-n5 opacity-75'/>
                                </div>
                                } */}
                                {/* {selectedTxtStyle !== "" && !isHTML && */}
                                {allFonts && allFonts.length > 0 &&
                                    <div className="me-3">
                                        <FormControl fullWidth>
                                            <InputLabel id="select-layout-label">Font</InputLabel>
                                            <Select 
                                                {...register("font")} 
                                                label="Font"
                                                className='w-250px'
                                                size='small'
                                                value={fontFam}
                                                onChange={(e) => {
                                                    handleChangeFont(e.target.value)                                                
                                                }}
                                                error={!! errors.font}                                        
                                                MenuProps={{ disablePortal: true }}
                                            >   
                                                { allFonts?.slice()
                                                    .sort((a,b)=> a.font_name.toLowerCase() > b.font_name.toLowerCase() ? 1:-1)
                                                    .map((fnt, ind) => {
                                                        // console.log("fnt.font_name: ", fnt.font_name)
                                                        // --------------------------------
                                                        return (
                                                            <MenuItem key={`font--${ind}`} value={fnt.font_name} 
                                                                className={`w-100`}
                                                                disabled={fnt.font_name.toLowerCase() === fontFam.toLowerCase()}
                                                                // style={{fontFamily: '"'+fnt.font_name+'"'}}
                                                            >
                                                                <span className={`font-${fnt.font_name.toLowerCase().replace(' ','-')}`}>{fnt.font_name}</span>
                                                            </MenuItem>
                                                        )
                                                    }) 
                                                }                                       
                                            </Select>
                                            <FormHelperText>{`${errors.font?.message ?? ''}`}</FormHelperText>
                                        </FormControl> 
                                    </div>
                                }
                                <div className=''>                                        
                                    <FormControl fullWidth>
                                        <InputLabel id="select-layout-label">Size</InputLabel>
                                        <Select 
                                            {...register("size")} 
                                            label="Size"
                                            className='w-75px'
                                            size='small'                                
                                            value={fontSize}
                                            onChange={(e) => {
                                                const numb = parseInt(""+ e.target.value)
                                                handleChangeSize(numb)                                                
                                            }}
                                            error={!! errors.size}                                        
                                            MenuProps={{ disablePortal: true }}
                                        >   
                                            { [ 8, 9, 10, 11, 12, 14, 16, 18, 
                                                20, 22, 24, 26, 28, 
                                                30, 32, 34, 36, 38,
                                                40, 42, 44, 46, 48,
                                                50, 52, 54, 56, 58,
                                                60, 62, 64, 66, 68,
                                                70, 72, 74, 76, 78,
                                                80, 82, 84, 86, 88,
                                                90, 92, 94, 96, 98,
                                                100, 120, 140, 160, 180,                                                
                                                200, 220, 240, 260, 280,
                                                300, 320, 340, 360, 380
                                                ].map((siz, ind) => {
                                                return (
                                                    <MenuItem key={`size--${ind}`} value={siz} 
                                                        className='w-100'
                                                        disabled={siz === fontSize}
                                                    >
                                                        {siz}  
                                                    </MenuItem>
                                                )
                                                }) 
                                            }                                       
                                        </Select>
                                        <FormHelperText>{`${errors.size?.message ?? ''}`}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='mb-2 d-flex justify-content-between'>                                        
                                <div className="btn-group btn-group-sm h-35px" role="group" aria-label="button group">
                                    <button type='button' className={`btn btn-sm w-35px bg-hover-gray-200 ${getValues("fontweight")==="bold"? "bg-light-primary":""}  rounded-0 rounded-start py-1 ps-3 pe-1`}
                                        onClick={()=>{ handleChangeStyle("fontweight") }}
                                    >
                                        <i className={`fa fa-bold ${getValues("fontweight")==="bold"? "text-primary":""}`}></i>
                                    </button>
                                    <button type='button' className={`btn btn-sm w-35px bg-hover-gray-200  ${getValues("fontitalic")==="italic"? "bg-light-primary":""} rounded-0 py-1 ps-3 pe-1`}
                                        onClick={()=>{ handleChangeStyle("fontitalic")}}
                                    >
                                        <i className={`fa fa-italic ${getValues("fontitalic")==="italic"? "text-primary":""}`}></i>
                                    </button>
                                    <button type='button' className={`btn btn-sm w-35px bg-hover-gray-200  ${getValues("fontdecoration")==="underline"? "bg-light-primary":""} rounded-0 py-1 ps-3 pe-1`}
                                        onClick={()=>{ handleChangeStyle("fontdecoration") }}
                                    >
                                        <i className={`fa fa-underline ${getValues("fontdecoration")==="underline"? "text-primary":""}`}></i>
                                    </button>                                   
                                    <button type='button' className={` btn btn-sm w-35px bg-hover-gray-200 ${getValues("fontdecoration")==="overline"? "bg-light-primary":""} rounded-0 py-1 ps-2 pe-1`}
                                        onClick={()=>{ handleChangeStyle("fontdecorationOverline")}}
                                    >
                                        <i className={`fa fa-underline mt-n1 ${getValues("fontdecoration")==="overline"? "text-primary":""}`} style={{transform: "scaleY(-1)"}}></i>
                                    </button>
                                    <button type='button' className={` btn btn-sm w-35px bg-hover-gray-200  ${getValues("fontdecoration")==="lineThrough"? "bg-light-primary":""} rounded-0 rounded-end py-1 ps-2 pe-1`}
                                        onClick={()=>{ handleChangeStyle("fontdecorationStrike")}}
                                    >
                                        <i className={`fa fa-strikethrough ${getValues("fontdecoration")==="lineThrough"? "text-primary":""}`}></i>
                                    </button>
                                </div>
                                <div className='d-flex ms-5'>
                                    <div className='symbol symbol-30px'>
                                        <div className='symbol-label bg-transparent'><i className='fa fa-font'></i></div>                                            
                                        <span className="bullet w-15px position-absolute translate-middle top-75 start-50" 
                                            style={{marginTop:"2px", height: "3px",
                                                backgroundColor: `${txtColor}`
                                            }}
                                        ></span>                                            
                                    </div>
                                    <div className='' tabIndex={-1}> 
                                        <FormControl fullWidth>
                                            <MuiColorInputStyled 
                                                // format="hex"  
                                                format="hex8"  
                                                value={txtColor} 
                                                size='small'
                                                variant="outlined"
                                                inputProps={{ 
                                                    style: {
                                                        border: "0px",
                                                        borderRadius: 0, 
                                                    }, 
                                                    sx: {
                                                        borderRadius: 0,
                                                        // '& > :not(style)': { m: 1, width: '25ch' },
                                                        disablePortal: true
                                                    }
                                                }}
                                                onChange={handleChangeTxtColor}
                                            />
                                            <FormHelperText>{`${errors.size?.message ?? ''}`}</FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>                                
                            </div>
                            <div className='mb-2 d-flex justify-content-between'>
                                {/* <div 
                                    className='rounded-end me-n1'
                                    style={{
                                        height: "36px",
                                    }}
                                >
                                    <Checkbox 
                                        checked={chkboxBgColor}
                                        className='tooltip-bottom'
                                        data-tooltip-content='Unset background color'
                                        disabled = {bgColor === ""}
                                        // hidden   = {bgColor === ""}
                                        onChange={()=>{
                                            handleChangeBgColor("", {
                                                hex: "",
                                                hsl: "",
                                                hsv: "",
                                                rgb: "",
                                                hex8: ""
                                            })
                                            setChkboxBgColor(! chkboxBgColor)
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}                                                                         
                                    />
                                </div> */}
                                <div className="d-flex justify-content-start mb-2"
                                    style={{height: "35px"}}
                                >                            
                                    <div className='form-check form-switch form-check-custom form-switch-sm form-check-solid'>
                                        <input className="form-check-input" type="checkbox" id="flexSwitchBgcolor" value="" 
                                            checked={chkboxBgColor}
                                            onChange={() => {    
                                                const tmpBgColor = chkboxBgColor ? "":"#ffffff"                                     
                                                handleChangeBgColor(tmpBgColor, {
                                                    hex: tmpBgColor,
                                                    hsl: "",
                                                    hsv: "",
                                                    rgb: "",
                                                    hex8: tmpBgColor+(tmpBgColor!==""?"ff":"")
                                                })

                                                setChkboxBgColor(! chkboxBgColor)                                                
                                            }}
                                        /> 
                                        <label className="form-check-label text-gray-700" htmlFor="flexSwitchBgcolor">
                                            Background
                                        </label>   
                                    </div>                                    
                                </div>
                                {chkboxBgColor &&
                                    <div className='d-flex ms-2'>
                                        <div className='symbol symbol-30px'>
                                            <div className='symbol-label bg-transparent'><i className='bi bi-paint-bucket fs-3'></i></div>                                            
                                            <span className="bullet w-15px position-absolute translate-middle top-75 start-50" 
                                                style={{marginTop:"2px", height: "3px",
                                                    backgroundColor: `${bgColor}`
                                                }}
                                            ></span>                                            
                                        </div>
                                        <div className='' tabIndex={-1}> 
                                            <FormControl fullWidth>
                                                <MuiColorInputStyled 
                                                    // format="hex"  
                                                    format="hex8"  
                                                    value={bgColor} 
                                                    size='small'
                                                    variant="outlined"
                                                    inputProps={{ 
                                                        style: {
                                                            border: "0px",
                                                            borderRadius: 0, 
                                                        }, 
                                                        sx: {
                                                            borderRadius: 0,
                                                            // '& > :not(style)': { m: 1, width: '25ch' },
                                                            disablePortal: true
                                                        }
                                                    }}
                                                    onChange={handleChangeBgColor}
                                                />
                                                <FormHelperText>{`${errors.size?.message ?? ''}`}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                }
                            </div> 
                            <div className='mb-8 d-flex justify-content-between'
                                style={{height: "35px"}}
                            >                                
                                <div className="d-flex justify-content-start mt-2 mb-2">                            
                                    <div className='form-check form-switch form-check-custom form-switch-sm form-check-solid'>
                                        <input className="form-check-input" type="checkbox" id="flexSwitchMarquee" value="" 
                                            checked={isMarqueeText}
                                            disabled={getValues("text")===""}
                                            onChange={() => {
                                                if (! isMarqueeText){
                                                    setIsHTML(false)
                                                    setValue('html', false)
                                                }
                                                setIsMarqueeText(! isMarqueeText)
                                                setValue('marquee', ! isMarqueeText)
                                            }}
                                        /> 
                                        <label className="form-check-label text-gray-700" htmlFor="flexSwitchMarquee">
                                            Scrolling
                                        </label>   
                                    </div>                                    
                                </div>
                                {isMarqueeText && getValues("text")!=="" &&
                                    <div className='h-35px d-flex align-items-center ms-1'>
                                        <div className="btn-group h-35px btn-group-sm ms-2" role="group" aria-label="button group">
                                            <button type='button' className={`btn btn-sm bg-hover-gray-200  ${getValues("direction")==="left"? "bg-light-primary":""} w-35px rounded-0 rounded-start py-2 ps-3 pe-1`}
                                                onClick={()=>{ handleChangeDirection("left") }}
                                            >
                                                <i className={`fa fa-arrow-left ${getValues("direction")==="left"? "text-primary":""}`}></i>
                                            </button>
                                            <button type='button' className={`btn btn-sm bg-hover-gray-200 ${getValues("direction")==="right"? "bg-light-primary":""} w-35px rounded-0 rounded-end py-2 ps-3 pe-1`}
                                                onClick={()=>{ handleChangeDirection("right") }}
                                            >
                                                <i className={`fa fa-arrow-right ${getValues("direction")==="right"? "text-primary":""}`}></i>
                                            </button>
                                            {/* <button type='button' className={`btn btn-sm bg-hover-gray-200  ${getValues("direction")==="up"? "bg-light-primary":""} w-35px rounded-0 py-2 ps-3 pe-1`}
                                                onClick={()=>{ handleChangeDirection("up") }}
                                            >
                                                <i className='fa fa-arrow-up'></i>
                                            </button>
                                            <button type='button' className={`btn btn-sm bg-hover-gray-200  ${getValues("direction")==="down"? "bg-light-primary":""} w-35px rounded-0 rounded-end py-2 ps-3 pe-2`}
                                                onClick={()=>{ handleChangeDirection("down") }}
                                            >
                                                <i className='fa fa-arrow-down'></i>
                                            </button> */}
                                        </div>
                                    </div>
                                } 
                                {isMarqueeText && getValues("text")!=="" &&
                                    <div className='d-flex align-items-center ms-1'>
                                        <TextField
                                            {...register("speed")}
                                            type='number'
                                            label= "Speed"                                            
                                            size='small'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{                                                 
                                                style: { 
                                                    width: "50px"
                                                } 
                                            }}        
                                            onChange={(e)=> {
                                                const numb = parseInt(""+ e.target.value)
                                                handleChangeSpeed(numb)
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div className='mb-4 d-flex'
                            style={{
                                background:`#00000088 url(${defaultTransparentGrids[1]}) no-repeat center center / cover`
                              }}
                            >                    
                                <TextField
                                    {...register("text")}
                                    className={`fw-bold text-primary`}
                                    fullWidth
                                    multiline
                                    rows={15}
                                    // disabled = {selectedTxtStyle === ""}
                                    label="Text"
                                    InputLabelProps={{ 
                                        shrink: true,                        
                                        className: fontFam? "font-"+ fontFam.toLowerCase().replace(' ','-'): "",                        
                                    }}
                                    inputProps={{ 
                                        // inputComponent: TextareaAutosize,
                                        // rows: 5,

                                        // sx:{
                                        //     "& fieldset": { border: 'none', borderRadius: '50px' },
                                        // },
                                        
                                        style: { 
                                            lineHeight: "normal",
                                            maxHeight: "225px",
                                            overflow: "auto",
                                            // textTransform: "uppercase",
                                            // borderRadius: "0", 
                                            paddingTop: "10px",                                            
                                            fontWeight: getValues("fontweight"),
                                            fontStyle: getValues("fontitalic"),
                                            textDecoration: getValues("fontdecoration") === "lineThrough"? "line-through": getValues("fontdecoration"),                                                    
                                            color: ""+ getValues("fontcolor"),
                                            backgroundColor: ""+ getValues("backgroundcolor"),
                                            fontSize: parseInt(""+getValues("size"))/2
                                        } 
                                    }}                      
                                />                  
                            </div>
                            
                            {/* } */}
                            {/* WYSIWYG editor for HTML text */}
                            {/* {selectedTxtStyle !== "" && isHTML &&
                            <TextEditor className='' />
                            } */}

                            
                            <div className='text-end mt-10'>
                                {/* <button type='button' className='btn btn-secondary pt-2 pb-3 me-3'
                                    data-bs-dismiss="offcanvas"
                                    data-kt-drawer-dismiss="true"
                                >
                                    <i className='fa fa-times me-1'></i>
                                    Cancel
                                </button> */}
                                {/* <button type='submit' className='btn btn-primary pt-2 pb-3'
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    disabled={isSubmitting || !isValid }
                                    // disabled={isSubmitting || !isValid || (customErrs !== null)}
                                >
                                    <i className='fa fa-save me-1'></i>
                                    Apply
                                </button> */}
                            </div>
                        
                        </form>
                    }          
                </div>
            </div>
    )
}

export {DrawerTextEditor}