/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { SyntheticEvent, useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
// import { useGetAllMediasQuery } from '../../../../redux/slices/media/apiMediaSlice';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled, withStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps } from '@mui/material/Accordion';
import {  AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { Box } from '@mui/material';
import moment from 'moment';
import { 
  AlignmentList,
  DimensionsList,
  Element,
  Source,
  TransitionList,
  secondsToHHmmss, 
  toCapitalize
} from '../../../../../redux/slices/content/contentModels';
import { setCheckSum, setContentSource, setHoverLayerId, setHoverLayerOrder, setHoverLayerSourceId, setHoverSectionId, setLayerId, setLayerOrder, setSectionId, setUnselectedSectionIds } from '../../../../../redux/slices/content/contentSlice';
import Swal from 'sweetalert2';
import { DEBUG_MODE, getSectColorCodes, getSectionIcon } from '../../../../../redux/slices/editor/editorModels';
import { SettingsSuggest } from '@mui/icons-material';
import { RenderedHtmlText } from '../../EditorComponents';

//----------------------------------------

type Props = {
  className?: string,
}

// const Accordion = styled((props: AccordionProps) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
//   '&:before': {
//     display: 'none',
//   },
// }));

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark'
//       ? 'rgba(255, 255, 255, .05)'
//       : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//   },
//   '& .MuiAccordionSummary-content': {
//     marginLeft: theme.spacing(1),
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)',
// }));

// const Accordion = withStyles({
//   root: {
//     "&$expanded": {
//       margin: "auto"
//     }
//   },
//   expanded: {}
// })(MuiAccordion);


//----------------------------------------
const AccordionSections: React.FC<Props> = ({className}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)  
  // console.log("currPersistContent in Accordion",currPersistContent)

  // console.log("--------------------------------------------------------")
  const unselectedSectIds = useAppSelector(state => state.content.unselectedSectionIds)  
  // console.log('unselectedSectIds:', unselectedSectIds)
  const currSectId = useAppSelector(state => state.content.currSectionId)  
  // console.log('currSectId:',currSectId)
  const currLayerId = useAppSelector(state => state.content.currLayerId)  
  // console.log('currLayerId:',currLayerId)
  const currLayerOrder = useAppSelector(state => state.content.currLayerOrder)  
  // console.log('currLayerOrder:',currLayerOrder)

  const hoverSectId = useAppSelector(state => state.content.hoverSectionId)  
  // console.log('hoverSectId:',hoverSectId)
  const hoverLayerId = useAppSelector(state => state.content.hoverLayerId)  
  // console.log('hoverLayerId:',hoverLayerId)
  const hoverLayerOrder = useAppSelector(state => state.content.hoverLayerOrder)  
  // console.log('hoverLayerOrder:',hoverLayerOrder)
  const hoverLayerSourceId = useAppSelector(state => state.content.hoverLayerSourceId)  
  // console.log('hoverLayerSourceId:',hoverLayerSourceId)
  
  const selectedLayer = currPersistContent.elements.find((sect)=>sect.element_id === currSectId)?.sources.find((layer)=>layer.source_list_id === currLayerId)
  // console.log('selectedLayer:',selectedLayer)
  // console.log("...selectedLayer", {...selectedLayer})

  // --------------------------------------------------
  const handleDeleteSrc = (sect: Element, src: Source) => {    

    try{
        
      // Remove a src from the currContentSection
      // -------------------------------------------------
      const recentSect = currPersistContent.elements.find((ele)=> {return ele.element_id === sect.element_id})

      var sourceList: Source[] = recentSect 
                                      ? (recentSect.sources
                                          ? recentSect.sources.filter((s)=> {                            
                                              return s.source_list_id !== src.source_list_id
                                            })
                                          : []
                                        )
                                      : [] 

      // Rendering the order of the sources
      sourceList = sourceList.map((s, ind) => {
        return {...s, order: (sourceList.length - ind)}
      })        
      // console.log("sourceList after: ",sourceList)      
      // console.log("sourceList after: ",sourceList.map((src) => {return src?.order}))

      dispatch(setSectionId(sect.element_id))  //which section it belongs to
      dispatch(setContentSource(sourceList))                

      dispatch(setHoverLayerId(sourceList.length<=0 ? "": sourceList[0].source_list_id))  //which section it belongs to
      dispatch(setHoverLayerOrder(sourceList.length))  //which section it belongs to
      dispatch(setHoverLayerSourceId(sourceList.length<=0 ? "": sourceList[0].source_id))

            
    }catch(error){
      Swal.fire(
          'Source: '+ src.element_id,
          'There is something wrong during the deletion!',
          'error'
      )                                    
    } 
  }


  // ---------------------------------------------------------
  const handleSwappingSrcs = (sect: Element, hoverLayerOrder: number, funcName: "swap up" | "swap down" | "go first" | "go last" = "swap up") => {    
    if (hoverLayerOrder > 0){
      try{
        // convert in order to get the correct order
        const matchingOrder = sect.sources.length - hoverLayerOrder
        var newSourceList: Source[] = []

        if (funcName === "swap up") { // Swap Up
          newSourceList = [
            ...sect.sources.slice(0, matchingOrder),
            ...sect.sources.slice(matchingOrder+1, matchingOrder+2),  // Swaping position
            ...sect.sources.slice(matchingOrder, matchingOrder+1),  // Swaping position            
            ...sect.sources.slice(matchingOrder+2)
          ]
        }else if (funcName === "swap down"){  // Swap Down
          newSourceList = [
            ...sect.sources.slice(0, matchingOrder-1),
            ...sect.sources.slice(matchingOrder, matchingOrder+1),  // Swaping position
            ...sect.sources.slice(matchingOrder-1, matchingOrder),  // Swaping position
            ...sect.sources.slice(matchingOrder+1)
          ]
        }else if (funcName === "go first"){  // Go first
          newSourceList = [
            ...sect.sources.slice(0, matchingOrder),
            ...sect.sources.slice(matchingOrder+1),
            ...sect.sources.slice(matchingOrder, matchingOrder+1),  // Swaping position
          ]
        }else if (funcName === "go last"){  // Go last
          newSourceList = [
            ...sect.sources.slice(matchingOrder, matchingOrder+1),  // Swaping position
            ...sect.sources.slice(0, matchingOrder),
            ...sect.sources.slice(matchingOrder+1)
          ]
        }
        
        // Rendering the order of the sources
        newSourceList = newSourceList.map((s, ind) => {
          return {...s, order: (newSourceList.length - ind)}
        })
          
        dispatch(setSectionId(sect.element_id))  //which section it belongs to
        dispatch(setContentSource(newSourceList))                

        // remove the highlight after click move UP/Down
        // dispatch(setSectionId(""))
        // dispatch(setLayerId(""))  
              
      }catch(error){
        Swal.fire(
            'There is something wrong during the swapping!',
            'error'
        )                                    
      } 
    }else{
      console.log("Warning: hoverLayerOrder is smaller than 0.")
    }
  }

  // const [expanded, setExpanded] = useState<false | string>(false);
  // const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
  //   console.log("panel", panel)
  //   console.log("isExpanded", isExpanded)
  //   // console.log("iconClick", iconClick)
  //   // setExpanded(isExpanded ? panel : false);
  // };
  
  // const accordionProps = {
  //   sx: {
  //     pointerEvents: "none"
  //   },
  //   expandIcon: (<>      
  //     <ExpandMoreIcon
  //       sx={{
  //         pointerEvents: "auto"
  //       }}
  //     />
  //     <SettingsSuggest
  //       sx={{
  //         pointerEvents: "auto"
  //       }}        
  //     />
  //   </>)
  // };
    

  //-----------------------------------------
  return (    
    <Accordion 
      // TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={true}
      square={true}
      disableGutters={true}   
      className='pb-2 border border-0'  
      style={{borderLeft: "0px"}}                
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className='p-3'        
        style={{backgroundColor:"#1e1e2d88"}}        
      >
        <Typography>
          <KTSVG path='/media/icons/duotune/layouts/lay001.svg' className='svg-icon-3 text-white me-2'/>
          <span className='text-white fw-semibold'>Sections, Slides</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className='m-0 p-0 mh-500px overflow-auto'      
      >
        {currPersistContent.elements?.slice().reverse()
        .map((sect, sectIndex)=> { 
          const sectDuration = sect.sources.reduce(
            (accumulator, currentSrc) => accumulator + (currentSrc.param_source[0].duration?currentSrc.param_source[0].duration:0)
            , 0
          )
          // console.log("sect.sources:", sect.sources)
          const clsNames = unselectedSectIds.includes(sect.element_id) ? ["text-gray-300", "bg-light"]:["text-muted",""]
          // -------------------------------
          return (
            <Accordion
              key={`sect-${sectIndex}`} 
              defaultExpanded={true}
              square={true}
              disableGutters={true}
              // disabled={true}  
              // expanded={expanded === `sect-${sect.element_id}`} 
              // onChange={handleChange(`sect-${sect.element_id}`)}   
              className='border border-0'
              style={{
                borderLeft: "0px",                
              }}              
            >
              <div className=''>
                <AccordionSummary
                  // {...accordionProps}
                  expandIcon={<ExpandMoreIcon/>}                             
                  aria-controls={`sect-${sect.alias}`}
                  id={`sect-${sect.alias}-header`}
                  className={`px-3 border-0 
                    ${unselectedSectIds.includes(sect.element_id) ? clsNames[1]
                      : sect.element_id === hoverSectId ? `bg-light-primary`:"bg-light"} 
                  `}
                  // style={{ borderLeft: "0px" }}                    
                  // style={{ color:"#331168"   }}
                > 
                  <div className='d-flex justify-content-between w-100'>
                    <div className={`ms-3 ${clsNames[0]}`}>
                      <span className='w-20px'>
                        {getSectionIcon(sect.element_type, clsNames[0])}                      
                      </span>                    
                      <span className='fs-9 fw-semibold text-truncate w-130px '>
                        {(currPersistContent.elements.length - sectIndex).toString().padStart(2, '0')}
                      </span>                        
                    </div>
                    
                    <div className="position-absolute top-0 start-0 opacity-100"
                      style={{paddingTop: "2px", paddingLeft: "5px"}}
                      onClick={()=>{
                          dispatch(setUnselectedSectionIds(
                            !unselectedSectIds.includes(sect.element_id) 
                              ? [...unselectedSectIds, sect.element_id] 
                              : unselectedSectIds.filter((id)=> id !== sect.element_id)
                          ))
                      }}
                    >
                      {unselectedSectIds.includes(sect.element_id) 
                        ? <i className="fa fa-eye-slash text-gray-300 text-hover-primary shadow-sm" style={{fontSize: "9px"}}                            
                          ></i>
                        : <i className="fa fa-eye text-muted text-hover-primary shadow-sm" style={{fontSize: "9px"}}                          
                          ></i>                        
                      }
                    </div>
                    

                    {/* {sect.element_setting && sect.element_setting.transition &&
                    <div 
                      className='d-flex text-link text-hover-primary'
                      data-bs-toggle="offcanvas"
                      aria-controls="staticBackdrop"
                      data-bs-target="#kt_drawer_edit_section"
                      // onClick={handleIconClick(`sect-${sect.element_id}`)}
                      onClick={()=>{
                        // handleIconClick(`sect-${sect.element_id}`)
                        dispatch(setSectionId(sect.element_id))
                      }}
                    >         
                      <span className="fs-9 text-capitalize opacity-75 h-15px mt-0 ms-5">                    
                        {sect.element_setting.transition_timer>0? (sect.element_setting.transition_timer/1000.0)+"s ":" "} 
                        {sect.element_setting.transition &&
                          <i className={`${TransitionList[sect.element_setting.transition]} fs-8 ms-1 me-2`} title={toCapitalize(sect.element_setting.transition)}></i>
                        }                      
                      </span> 
                      <span className="badge badge-secondary text-white fs-9 py-0 px-1 h-15px me-1">
                        {sectDuration >0? secondsToHHmmss(sectDuration): ""}                       
                      </span>                    
                    </div>
                    } */}
                  </div>
                </AccordionSummary>
                
                {/* Custom Buttons on AccordionSummary */}
                <div className={`position-absolute top-0 end-0 me-9 pt-1 ps-0 text-hover-primary cursor-pointer
                    ${clsNames[0]}
                  `}
                >
                  {sect.element_setting && sect.element_setting.transition &&
                    <div 
                      className='link-hover-with-icon d-flex text-link text-hover-primary tooltip-bottom'
                      data-tooltip-content={`Edit section ${(currPersistContent.elements.length - sectIndex).toString().padStart(2, '0')}`}
                      data-bs-toggle="offcanvas"
                      aria-controls="staticBackdrop"
                      data-bs-target="#kt_drawer_edit_section"
                      onClick={()=>{
                        dispatch(setSectionId(sect.element_id))
                      }}
                    >         
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='link-hover-icon svg-icon-7 me-1' />
                      <span className="fs-9 opacity-75 h-15px mt-0 me-2">                    
                        {sect.element_setting.transition_timer>0? (sect.element_setting.transition_timer/1000.0)+" sec":""} 
                        {sect.element_setting.transition &&
                          <i className={`${TransitionList[sect.element_setting.transition]} fs-8 ms-1`} title={toCapitalize(sect.element_setting.transition)}></i>
                        }
                        {/* {sect.element_setting.fill_to 
                          ? sect.element_setting.fill_to !== "" 
                              ? <i className={`${DimensionsList[sect.element_setting.fill_to][0]} fs-8 ms-1`} title={toCapitalize(sect.element_setting.fill_to)}></i>
                              : <i className={`${DimensionsList["full"][0]} fs-8 ms-1`} title={toCapitalize("full")}></i>
                          : <i className={`${DimensionsList["full"][0]} fs-8 ms-1`} title={toCapitalize("full")}></i>
                        }
                        {sect.element_setting.alignment && sect.element_setting.alignment !== "" &&
                          <i className={`${AlignmentList[sect.element_setting.alignment][0]} fs-8 ms-1`} title={toCapitalize(sect.element_setting.alignment)}></i>
                        }                       */}
                      </span> 
                      <span className="badge badge-secondary text-white fs-9 py-0 px-1 h-15px me-1">
                        {sectDuration >0? secondsToHHmmss(sectDuration): ""}                       
                      </span>                    
                    </div>
                    }
                </div>
              </div>
              
              <AccordionDetails
                className="p-0"
                // onMouseOver={(e)=> e.target.}                
                // id="kt_drawer_edit_contents_button"
                // data-kt-drawer-show="true"
              > 
                {/* {sect.sources?.slice(0).reverse().map((src, ind)=> { */}
                {sect.sources?.slice()
                .sort((a,b)=> (a.order >= b.order? 1: -1))
                .map((src, ind)=> {
                  // console.log("src......",src)
                  // console.log("src..param_source[0]......",src.param_source[0])
                  // console.log("src.source......",src.source)
                  // console.log("src.url......",src.param_source[0].url)
                  // console.log("i......",ind)
                  // console.log("currLayerOrder......",currLayerOrder)
                  const isTextFlag  = src.param_source[0].text || src.param_source[0].text === "" ? true: false
                  const isVideoFlag = src.source && src.source.type === "video" ? true: false
                  const isWebFlag   = src.param_source[0].type === "web" ? true: false
                  
                  // -------------------------------------------
                  return (
                  <div 
                    key={`layer-${ind}`}
                    className={`overflow-hidden position-relative py-2 pe-3 ps-1 d-flex justify-content-between bg-hover-light-primary
                      ${(selectedLayer && (src.source_list_id === currLayerId && currLayerOrder === ind+1)) ? "bg-light-primary":"border-0"} 
                    `}
                    onClick={()=>{
                      dispatch(setSectionId(sect.element_id))
                      dispatch(setLayerId(src.source_list_id))
                      dispatch(setLayerOrder(ind+1))
                      if (isTextFlag){
                        dispatch(setCheckSum(Math.random()))
                      }                      
                    }}
                    onMouseOver={()=>{
                      dispatch(setHoverSectionId(sect.element_id))
                      dispatch(setHoverLayerId(src.source_list_id))
                      dispatch(setHoverLayerOrder(ind+1))                      
                      dispatch(setHoverLayerSourceId(src.source_id))
                    }}
                    onMouseOut={()=>{
                      dispatch(setHoverSectionId(""))
                      dispatch(setHoverLayerId(""))
                      dispatch(setHoverLayerOrder(-1))  //reset
                      dispatch(setHoverLayerSourceId(""))
                    }}
                  >
                    {/* Swap up and down buttons */}
                    {sect.sources.length > 1 &&
                      <div className={`w-50px px-1 ${(hoverLayerId && (src.source_list_id === hoverLayerId && hoverLayerOrder === ind+1)) ? "":"d-none"}`}>
                        <div className="btn-group btn-group-sm" role="group">
                          {ind > 0 
                            ? <>
                                <button type="button" className='btn btn-link btn-sm text-hover-primary m-0 py-0 px-0 tooltip-bottom'
                                  data-tooltip-content='First'
                                  onClick={(e)=> { handleSwappingSrcs(sect, hoverLayerOrder, "go first") }}
                                >
                                  <i className='fa fa-angle-double-up text-gray-400 fs-9'></i>
                                </button>
                                <button type="button" className='btn btn-link btn-sm text-hover-primary m-0 py-0 px-0 tooltip-bottom'
                                  data-tooltip-content='Swap up'
                                  onClick={(e)=> { handleSwappingSrcs(sect, hoverLayerOrder, "swap up") }}
                                >
                                  <i className='fa fa-arrow-up text-gray-400 fs-9'></i>
                                </button>
                              </>
                            : <div style={{width: "24px"}}>&nbsp;</div>
                          }
                          {ind < sect.sources.length -1 
                            ? <>
                                <button type="button" className='btn btn-link btn-sm text-hover-primary m-0 py-0 px-0 tooltip-bottom'
                                  data-tooltip-content='Swap down'
                                  onClick={(e)=> { handleSwappingSrcs(sect, hoverLayerOrder, "swap down")}}                                
                                >
                                  <i className='fa fa-arrow-down text-gray-400 fs-9'></i>
                                </button>
                                <button type="button" className='btn btn-link btn-sm text-hover-primary m-0 py-0 px-0 tooltip-bottom'
                                  data-tooltip-content='Last'
                                  onClick={(e)=> { handleSwappingSrcs(sect, hoverLayerOrder, "go last") }}
                                >
                                  <i className='fa fa-angle-double-down text-gray-400 fs-9'></i>
                                </button>
                              </>
                            : <div style={{width: "24px"}}>&nbsp;</div>
                          }
                        </div>
                      </div>                      
                    }
                    {(sect.sources.length <= 1 || !hoverLayerId || (src.source_list_id !== hoverLayerId)) &&
                      <div style={{width:'20px'}}>&nbsp;</div>
                    }

                    <div 
                      className={`d-flex text-hover-primary ms-2`}
                      data-bs-toggle="offcanvas"
                      aria-controls ="staticBackdrop"                      
                      data-bs-target={`${   isTextFlag  ? "#kt_drawer_text_editor" 
                                          : (isVideoFlag || isWebFlag)  ? "#kt_drawer_edit_layer" 
                                                                        : "#kt_drawer_edit_layer" //normal image
                                                                        // : "#kt_drawer_edit_section" //normal image
                                      }`}
                      // data-bs-target={`${!isTextFlag ? "#kt_drawer_edit_section" :"#kt_drawer_text_editor"}`}
                      // onClick={()=>{
                      //   dispatch(setSectionId(sect.element_id))
                      // }}
                      // data-bs-toggle={`${!isTextFlag ? "offcanvas"             :"modal"}`}
                      // aria-controls ={`${!isTextFlag ? "staticBackdrop"        :""}`}
                      // data-kt-menu-trigger="{default: 'click'}"                    
                    >
                      <div className={`w-25px symbol ${src.source && src.source.width >= src.source.height? "symbol-20px symbol-2by3":"symbol-20px symbol-3by2"} me-3`}>                        
                        { src.source && 
                          src.source.type ? {
                                              'image' : <div className="symbol-label rounded-0" style={{ backgroundImage: `url('${src.source && src.source.is_remote === 0 ? src.source.url_thumb : src.source.url}')`,}}>&nbsp;</div>,
                                              'video' : <div className="symbol-label rounded-0" style={{ backgroundImage: `url('${src.source && src.source.is_remote === 0 ? src.source.url_thumb : src.source.url}')`,}}>&nbsp;</div>,
                                              'audio' : <div className='symbol-label rounded-0'><i className='fa fa-music svg-icon-3x'></i></div>,
                                              'pdf'   : <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-2x m-0'/>
                                              // 'video' : <div className="symbol-label rounded-0" style={{ }}>
                                              //             <video loop={false} autoPlay={false} controls={false} muted 
                                              //               className={`${className} rounded-0 w-100 h-20px bordered-0`}
                                              //             >
                                              //               <source src={`${src.source.url}#t=10`} type="video/mp4" />
                                              //             </video>
                                              //           </div>,
                                            }[src.source.type]
                                          : src.param_source[0].type === "web"
                                            ? <div className="symbol-label rounded-0 bg-light-primary">
                                                <i className="bi bi-globe text-primary fs-8 mx-3"></i>
                                              </div>
                                            : isTextFlag && 
                                              <div className="symbol-label rounded-0">
                                                <KTSVG path='/media/icons/duotune/art/art001.svg' className='svg-icon-8 mx-3'/>
                                              </div>
                        }

                        {/* icon bottom-right */}
                        {/* ----------------- */}
                        <div className="position-absolute top-50 start-75 opacity-100 ps-1">
                          { src.source && 
                            src.source.type && {                            
                                                'video' :  <i className="fa fa-video-camera text-info shadow-sm fs-9"></i>,
                                              }[src.source.type]
                          }                          
                        </div>
                      </div>

                      {/* text display  */}
                      {/* ------------- */}
                      <div className={`d-flex ms-0 pt-0 text-truncate text-hover-primary small fw-light text-muted`}
                        style={{width : (hoverLayerId && (src.source_list_id === hoverLayerId) && (sect.sources.length > 1)) ? "50px":"85px"}}                        
                      >
                        {/* <span className='me-1'>{src.order}. </span>
                        <span className='me-1'>{src.source_list_id}. </span> */}
                        { src.source &&   
                          src.source.type ? {
                                              'image' :  <span>{src.source?.name}</span>,
                                              'video' :  <span>{src.source?.name}</span>,
                                              'audio' :  <span>{src.source?.name}</span>,
                                              'pdf'   :  <span>{src.source?.name}</span>,
                                            }[src.source.type]
                                          : src.param_source[0].type === "web"
                                              ? <span>{src.param_source[0].url}</span>
                                              : isTextFlag && src.param_source[0].text &&  //'text'
                                                  <RenderedHtmlText className='' item={src.param_source[0]} isLabel={true}/>
                                                  // <div style={{
                                                  //         height: "20px",
                                                  //         color: src.param_source[0].fontcolor? src.param_source[0].fontcolor: "",
                                                  //         opacity: 0.25
                                                  //       }} 
                                                  //       dangerouslySetInnerHTML={{ __html: src.param_source[0].text }} 
                                                  // />
                        }
                      </div>
                    </div> 
                    
                    {/* Duration */}
                    {/* -------- */}
                    <div className='d-flex justify-content-end mt-1 ms-2'
                      style={{width:"150px"}}
                    >                                              
                      {(src.param_source && src.param_source[0].bg_color) &&
                        <span className='me-1 mt-n1'>
                            <i className={`bi bi-back fs-9 ${src.param_source[0].bg_color} tooltip-bottom`}
                              style={{color : `${src.param_source[0].bg_color}`}}                                
                              data-tooltip-content={`${src.param_source[0].bg_color}`}
                            ></i>
                        </span>                          
                      }
                      {(src.param_source && src.param_source[0].fill_to) &&
                        <span className='me-1 mt-n1'>
                          {
                            {
                              "full": <i className='bi bi-arrows-fullscreen fs-9'></i>,
                              "fit" : <i className='bi bi-fullscreen fs-9'></i>,
                              "original": <i className='bi bi-image fs-9'></i>
                            }[src.param_source[0].fill_to]
                          }
                        </span>                          
                      }
                      {(src.param_source && src.param_source[0].alignment) &&
                        <span className='me-1 mt-n1'>
                          {
                            {
                              "left"  : <i className='bi bi-box-arrow-in-left fs-9'></i>,
                              "right" : <i className='bi bi-box-arrow-in-right fs-9'></i>,
                              "center": <i className='bi bi-arrows-move fs-9'></i>,
                              "top"   : <i className='bi bi-box-arrow-in-up fs-9'></i>,
                              "bottom": <i className='bi bi-box-arrow-in-down fs-9'></i>,
                              "top left" : <i className='bi bi-box-arrow-in-up-left fs-9'></i>,
                              "top right": <i className='bi bi-box-arrow-in-up-right fs-9'></i>,
                              "bottom left": <i className='bi bi-box-arrow-in-down-left fs-9'></i>,
                              "bottom right": <i className='bi bi-box-arrow-in-down-right fs-9'></i>,
                            }[src.param_source[0].alignment]
                          }
                        </span>                          
                      }
                      {(src.param_source && src.param_source[0].duration && src.param_source[0].duration >= 0)
                        ? <span className="badge badge-secondary text-white fs-9 py-0 px-1 h-15px">
                            {secondsToHHmmss(src.param_source[0].duration)}
                          </span>
                        : <span className="badge badge-transparent text-white fs-9 py-0 px-1 h-15px" hidden>
                            {secondsToHHmmss(0)}
                          </span>
                      }
                      <div className='text-hover-primary'>
                        <button type="button" className='btn btn-link btn-sm m-0 mt-n2 p-0 '
                          onClick={()=> handleDeleteSrc(sect, src)}
                        >
                          <i className='fa fa-times text-gray-400 fs-9 ms-3'></i>
                        </button>
                      </div> 
                    </div>
                                      
                  </div>
                  )}
                )}
              </AccordionDetails>
            </Accordion>
          )}
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export {AccordionSections}