import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useFormik } from 'formik'
import clsx from 'clsx'
import { enableGoogle2FAOnSignUp, validateAccount, verifyAccount } from "../core/_requests";
import { Recaptcha } from "../../../commons/widgets/ReCaptcha";
import * as Yup from 'yup'
import { TFAQRCode } from "../../../commons/widgets/TFAQRCode";

const initialValues = {
  password: '',
  changepassword: ''
}

const activateSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(16, 'Maximum 16 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),

  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    })
})

export function ActivateAccount() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let activation = searchParams.get('_req');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await validateAccount(
          activation
        )
        if (data.status !== 200) {
          navigate('/auth')
        }
      } catch (error) {
        navigate('/auth')
      }
    }
    fetchData().catch(console.error)
  }, []);

  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState('');
  const [stage, setStage] = useState(1)
  const [validateCode, setValidateCode] = useState('')
  const [validateResult, setValidateResult] = useState('');
  const [mfaSecret, setMfaSecret] = useState('');
  const [mfaQrCode, setMfaQrCode] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: activateSchema,
    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await verifyAccount(
          activation, values.password
        );
        if (auth.code === 200) {
          setMfaSecret(auth.data.mfaSecret)
          setMfaQrCode(auth.data.qrCode)
          setStage(2)
          resetForm()
          setLoading(false)
        } else {
          setResult(auth.message)
          setSubmitting(false)
        }
      } catch (error) {
        setResult('The registration link has expired.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  });
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const enableMFA = async (key: string, code: string) => {
    try {
      const response: any = await enableGoogle2FAOnSignUp(key, code)
      if (response.status === 200) {
        setValidateResult('success')
      } else {
        setValidateResult('fail')
        setValidateCode('')
      }
    } catch (error) {
      setValidateResult('fail')
      setValidateCode('')
    }
  }

  const validateMFA = async () => {
    setValidateCode('');
    setValidateResult('');
    setStage(3);
  }

  const chooseMFA = async () => {
    setStage(2);
    setValidateCode('');
    setValidateResult('');
  }
  // -----------------------------------------
  return (<>{
    stage == 1 ? (
      /**
       * Display Password page
       */

      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Activate account</h1>
          <div className='text-gray-500 fw-semibold fs-6'>Your account is almost ready to use.</div>
          <div className='text-gray-500 fw-semibold fs-6'>Please create your account password.</div>
        </div>
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>

        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
        {result != '' &&
          <div className=' position-relative text-danger border-solid rounded p-5 mb-5 min-w-175px bg-light-danger'>
            <span className="fs-3">
              Unable to activate your account.
            </span><br />
            <span className="badge badge-light-danger fs-7 text-wrap text-start">
              Please try to activate your account again.
            </span>
          </div>

        }
        {/* end::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Activate</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <Recaptcha pageName="activate" />
      </form>
    )
      :
      stage == 2 ? (
        /**
         * Display two factor authentication selection.
         */
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Superbolt Account Activated</h1><br />
          <div className="text-gray-500 fw-semibold">
            <div className='mb-lg-15 alert alert-success'>
              <div className='alert-text mb-5 fs-3'>
                Add extra security to your account
              </div>
              <div className='text-muted fs-7 mb-5 text-start'>
                Two-factor authentication protect your account by requiring an additional code when you sign in your account.
              </div>
              <div className='fw-bolder text-dark mb-5 text-start'>
                Choose your security method
              </div>
              <div className="accordion" id="accordionTFA">
                <div className="accordion-item">
                  <h2 className="accordion-header bg-light-info" id="use_app_header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#use_app" aria-expanded="false" aria-controls="use_app">
                      <div className='d-block'>
                        <div className='text-dark mb-3'>Authentication app</div>
                        <div className='text-success'>
                          <span className='text-success'>
                            [Recommended]&nbsp;
                          </span>
                          <span className='text-muted'>
                            We'll recommend an app to download if you don't have one. It'll generate a code that you'll enter when you sign in.
                          </span>
                        </div>
                      </div>

                    </button>
                  </h2>
                  <div id="use_app" className="accordion-collapse collapse" aria-labelledby="use_app_header" data-bs-parent="#accordionTFA">
                    <div className="accordion-body">
                      <TFAQRCode mfaSecret={mfaSecret} mfaQRCode={mfaQrCode} />
                      <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-gradient-primary w-100 mb-5'
                        onClick={() => validateMFA()}
                      >
                        I have scanned the QR Code.
                      </button>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header bg-light-info" id="use_email_header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#use_email" aria-expanded="true" aria-controls="use_email">
                      <div className='d-block'>
                        <div className='text-dark mb-3'>Send code to email.</div>
                        <div className='text-success'>
                          <span className='text-muted'>
                            We'll send an access code to your registered email address.
                          </span>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div id="use_email" className="accordion-collapse collapse show" aria-labelledby="use_email_header" data-bs-parent="#accordionTFA">
                    <div className="accordion-body">
                      <div className='text-muted mb-'>
                        I don't want to use an authentication app. Please send the 6-digit access code to my email address every time I sign in my account.
                      </div>
                      <Link to='/auth/login'>
                        <button
                          type='button'
                          id='kt_login_login_button'
                          className='btn btn-lg btn-gradient-primary w-100 mb-5'
                        >
                          Sign In
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
        :
        (
          /**
           * Display validate app authenticator.
           */
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Superbolt Account Activated</h1><br />
            <div className="text-gray-500 fw-semibold">
              <div className='mb-lg-15 alert alert-success'>
                <div className='alert-text mb-5 fs-3 text-success'>
                  Verify your authentication app.
                </div>
                <div className='text-dark mb-1 text-start'>Get access code from your authentication app.</div>
                <div className='text-muted fs-7 mb-5 text-start'>
                  Enter the 6-digit code generated by your authentication app
                </div>
                <div>
                  <input
                    type='tel'
                    placeholder='Enter code'
                    autoComplete='off'
                    name="passcode"
                    className='form-control mb-7'
                    value={validateCode}
                    onChange={evt => setValidateCode(evt.target.value)}
                  />
                  {
                    validateResult == 'success' &&
                    <div className=' position-relative text-info border-solid rounded min-w-175px p-5 border-info bg-light-info'>
                      <span className="fs-3">
                        Authentication app has been verified.
                      </span>
                    </div>
                  }
                  {
                    validateResult == 'fail' &&
                    <div className=' position-relative text-danger border-solid rounded p-5 min-w-175px bg-light-danger'>
                      <span className="fs-3">
                        Authentication app verification has failed.
                      </span><br />
                      <span className="badge badge-light-danger fs-7 text-wrap text-start">
                        Please verify the code again. Or click back button below to re-scan the QR Code.
                      </span>
                    </div>
                  }
                  {
                    validateResult == 'success' ?
                      <div>
                        <Link to='/auth/login'>
                          <button
                            type='button'
                            id='kt_login_login_button'
                            className='btn btn-lg btn-gradient-primary w-100 mt-7'
                          >
                            Sign In
                          </button>
                        </Link>
                      </div>
                      :
                      <div>
                        <button
                          type='button'
                          id='kt_sign_up_submit'
                          className='btn btn-lg btn-gradient-primary w-100 mt-7'
                          onClick={() => enableMFA(mfaSecret, validateCode)}
                        >
                          Submit
                        </button>
                        <button
                          type='button'
                          id='kt_sign_up_submit'
                          className='btn btn-lg btn-secondary w-100 mt-3'
                          onClick={() => chooseMFA()}
                        >
                          Back
                        </button>
                      </div>
                  }

                </div>
              </div>
            </div>
          </div>
        )
  }</>)
}