import moment from 'moment'
import {CarbonDate, Group, OptionProps} from '../group/groupModels'
import { AccountLicensePackage } from '../license/licenseModel'
import { AssociativeArray } from '../content/contentModels'
import { AuthModel } from '../../../modules/auth'

export interface InitialState {
    currDevice: Device,
    mqttDevice: any,
    errStatus: boolean,
    arrMQTTResponses : any,    
    currDeviceMqttClient : any | null,
    lastDeviceStatus: any[]
}

export interface Address{
    address: string,
    suburb: string,
    city: string,
    state?: string,
    country?: string
}

// export interface DeviceParams{

// }

// export interface Device{
//     id: string
//     deviceID: string,
//     deviceUnique: string,
//     PIN?: string,
//     deviceName: string,    
//     model: string,            
//     status: string,            
//     cyb_service_online: boolean,
//     type: string,        
//     lastUpdated : string,
//     avgWeekUsage : number,

//     address?: Address,
//     expiration?: {
//         isExpired: boolean,
//         expiredDate: string
//     },
//     groupId?: string,
//     params?: DeviceParams
//     // userId?: string
// }


// export interface MQTTDeviceResponses{
//     device: string,
//     arrResponses: MQTTResponse[],    
// }

// export interface MQTTResponse{
//     topic: string,
//     message: string
// }

export interface WorkerMessageEvents{
    devices: Device[],
    action: string,
    auth:  any,
}

export interface Other{
    deviceId: string,
    licensePackageId: string,
    pin: string
}

export interface iDeviceOfflineNotification{
    deviceId: string,
    isEnable: boolean | number,
    email: null | string, /* If null, will pick up account email address */
    lastEmailSent: null | CarbonDate
}

export interface DeviceSettings{
    rotation: string,
    device_time_zone?: string,
    show_time?: boolean | number
}

export interface Device{
    id          : string,
    deviceName  : string,
    // rotation    : string,
    groupId     : string,

    groupName?   : string,
    groupDisplayType?: string,
    category?    : string,
    groupUpdatedAt?: CarbonDate | null,
    groupCreatedAt?: CarbonDate | null,

    show_time?    : boolean,    //display digital clock on top-right of the screen

    app_ver?     : string,
    checkin_time?: string,
    created_at?  : CarbonDate | null,
    updated_at?  : CarbonDate | null,
    auto_signin? : number,
    device_manufacturer?: string,
    device_model?: string,     
    device_time_zone?: string,
    external_storages?: string,
    deviceUnique?: string,    
    has_camera?  : boolean,
    has_touch?   : boolean,    
    note?        : string,
    settings?    : string,
    pin?         : string,
    platform?    : string,
    platform_ver?: string,    
    status_id?   : number,
    type?        : string,   
    userId?      : string,  
    account_license_package_id?: string,
    status?: string, // to be deleted
    contract?: Contract[],
    notification?: iDeviceOfflineNotification,
    licensePackageId?: string,
    deviceId?        : string,
    user_settings : DeviceSettings,
    license?: AccountLicensePackage
}

/*
export interface Device{
    PIN?: string,
    apk_signature?   : string | null,
    apk_version?     : string | null, 
    auto_signin?     : number,
    avgWeekUsage?    : number,
    channel?         : number,
    checkin_time?    : string | null,
    created_at?      : string | null,
    cyb_service_online?   : boolean,
    deleted_at?      : string | null,
    deviceID        : string,
    deviceName      : string,
    deviceUnique?    : string,    
    device_height?   : string,
    device_id?       : number,
    device_name?     : string,
    device_params?   : string,
    device_unique?   : string,
    device_width?    : number,
    disabled_keycodes?   : string,
    display_time?    : number,
    display_time_mode?   : number,
    display_time_position?   : number,    
    display_watermark?   : number,
    display_weather? : number,
    display_weather_position?    : number,
    enable_external? : number,
    groupId?         : string,
    group_id?        : number,
    id?              : string,    
    last_http_header?: string,
    last_status_change? : string | null
    mac_address?     : string,
    manufacturer?    : string,
    model?           : string,
    note?            : string | null,
    os_version?      : string | null,    
    padding_h?       : number,
    padding_v?       : number,
    portnum?         : number,
    remoteip?        : string,
    restart?         : number,
    rotation?        : number,
    settings?        : string | null,
    status          : string,
    status_id?       : number,
    subscription_plan_id?    : number,
    timezone?        : string,
    turn_device_off? : string | null,
    turn_device_on?  : string | null,
    type            : string,
    updated_at?      : string | null,
    user_id?         : number,
    watermark_position?  : number,
    weather_city_code?   : number | null    
}
*/

export interface PushNotification {
    deviceId: string,
    event: string,
    data?: any,
    subscriber: string,
}

export const availablePushEvent = {
    restartPlayer :'restartPlayer',
    restartService: 'restartService',
    screenshot : 'screenshot',
    deviceInfo : 'deviceInfo'
}
 
export interface Contract{
    created_at  : string | null,
    created_by  : string | null,
    deviceId    : string,
    end_date    : string | null,
    last_email_sent : string | null,
    num_email_sent  : number,
    start_date  : string | null,
    status_id   : number,
    terms       : string
}


// Data Pre-assignment
// ----------------------------------------------
export const EXPIREDWARNINGTHRESDHOLD = 30 // 30 days
export const OFFLINEPERIODTHRESHOLD   = 5  //refer to 5mn
// export const OFFLINEPERIODTHRESHOLD = 15 * 1440 //1440mn = 1days
export const rotationOptions: OptionProps[] = [    
    { value: '0',   label: '0 degree'},
    { value: '-90', label: '-90 degree (Anti-clockwise)'},    
    { value: '90',  label: '+90 degree (Clockwise)'},
]
export const LICENCEPACKAGE_FOR_FREELICENSE = ["R5jMRl09Ew"] //freeLicense licPackageIds
export const LICENCEPACKAGE_FOR_SMB         = ["qgzOnk03aG"] //freeLicense licPackageIds
export const LICENCEPACKAGE_FOR_CORPORATE   = ["4K50vb0n9V"] //corporate licPackageIds
export const LICENCEPACKAGE_FOR_ENTERPRISE  = ["BJDMkm0Eya"] //enterprise licPackageIds
export const LICENCEPACKAGE_FOR_SB_PARTNER  = ["6q2MjY8PGx"] //sbPartner licPackageIds
export const LICENCEPACKAGE_FOR_SB_PARTNER_WATERMARK  = ["myv83QOrBZ"] //sbPartner Watermark licPackageIds
export const LICENCEPACKAGE_FOR_ADVERTISER  = ["YvyMYNO4wz"] //sbAdvertiser licPackageIds

export const LICENCEPACKAGE_FOR_ADVANCE_FEATURES = [
                                                        ...LICENCEPACKAGE_FOR_FREELICENSE,
                                                        ...LICENCEPACKAGE_FOR_CORPORATE,
                                                        ...LICENCEPACKAGE_FOR_ENTERPRISE,
                                                        ...LICENCEPACKAGE_FOR_SB_PARTNER,
                                                        ...LICENCEPACKAGE_FOR_SB_PARTNER_WATERMARK,
                                                        ...LICENCEPACKAGE_FOR_ADVERTISER
                                                    ] //corporate and entreprise licPackageIds

export const emptyDevice: Device = {
    id          : "",
    deviceName  : "",
    // rotation    : "",
    groupId     : "",

    groupName   : "",
    groupDisplayType : "",
    category    : "",
    groupCreatedAt: null,
    groupUpdatedAt: null,
    user_settings: {
        rotation: "0",
        device_time_zone: "Australia/Melbourne"
    },
    checkin_time: "",
}


// List of functions
// ----------------------------------
export const isDeviceOnline = (checkInTime: string, liveStatus?: string) => {
    // console.log(checkInTime," duration(", moment().diff(moment(checkInTime), 'minutes'),") - isOnline: ",(moment().diff(moment(checkInTime), 'minutes') <= OFFLINEPERIODTHRESHOLD)?"true":"false")

    // console.log("checkInTime: ",checkInTime)
    // console.log("moment().diff(moment(checkInTime), 'minutes'): ", moment().diff(moment(checkInTime), 'minutes'))

    var isOnline = 0
    // ----------------------------------
    if (liveStatus){
        if (["online"].includes(liveStatus) ){
          isOnline = 1
        }else if (["screen-off"].includes(liveStatus) ){
            isOnline = 2
          }
    }else{
        isOnline = (moment().diff(moment(checkInTime, "DD-MMM-YYYY  HH:mm:ss"), 'minutes') <= OFFLINEPERIODTHRESHOLD) ? 1 : 0
    }

    return  isOnline
}


export const filterOnlineDevices = (allDevices: Device[] | undefined) => {

    if (allDevices && allDevices.length>0){        
        const result = allDevices.filter((dev)=> {
            const dateStr = moment(moment.utc(dev.checkin_time).toDate()).local().format("DD-MMM-YYYY HH:mm:ss")
            // console.log("moment: ",moment().format("DD-MMM-YYYY HH:mm:ss"))
            // console.log("dateStr: ",dateStr)
            // console.log("(dev.groupId !== ''): ",(dev.groupId !== ""))
            // console.log("dev.checkin_time? isDeviceOnline(dateStr) : false: ",dev.checkin_time? isDeviceOnline(dateStr) : false)
            return (dev.groupId !== "") && (dev.checkin_time? isDeviceOnline(dateStr) : false)
        })
        // console.log("result : ",result)

        return result
    }else{
        return []
    }    
}

export const filterOfflineDevices = (allDevices: Device[] | undefined) => {

    if (allDevices && allDevices.length>0){        
        const result = allDevices.filter((dev)=> {
            const dateStr = moment(moment.utc(dev.checkin_time).toDate()).local().format("DD-MMM-YYYY HH:mm:ss")
            return (!((dev.groupId !== "") && (dev.checkin_time? isDeviceOnline(dateStr) : false)))
        })
        return result
    }else{
        return []
    }    
}

export const filterScheduledDevices = (allSchedGrps: Group[] | undefined, selectedDevices?: Device[]) => {
    if (allSchedGrps){
        var arrDevices: Device[] = []

        allSchedGrps.filter((grp)=> {
            if (grp.devices){
                let filteredDevices = grp.devices
                if (selectedDevices){
                    const selectedDeviceIds = selectedDevices.map((dev)=> dev.id)
                    filteredDevices = grp.devices.filter((dev)=> selectedDeviceIds.includes(dev.id))
                }
                arrDevices = [...arrDevices, ...filteredDevices]    //merge two array
            }
        })
        return arrDevices        
    }else{
        return []
    }    
}