/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'

import {
    TextField,
    Stack,
    Slider
} from '@mui/material'
// import Select from "react-select";

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import { Source, SourceInput } from '../../../../redux/slices/content/contentModels'
import { useUpdateContentMutation } from '../../../../redux/slices/content/apiContentSlice'
import { setContentSourceLayer, setLayerId, setSectionId } from '../../../../redux/slices/content/contentSlice'

import { VolumeDown, VolumeUp } from '@mui/icons-material'
import { defaultVideoVolume, maximumVideoVolume, minimumVideoVolume } from '../../../../redux/slices/editor/editorModels'

type Props = {
    className?: string
}

const schema = yup.object().shape({

    order: yup.number().required(),
    dur: yup.number().required(),
    url: yup.string()
        .matches(
            // /^((https?):\/\/)(www.)?[a-zA-Z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            // 'Enter correct URL!  (started with http://........ or https://........)'            
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
            'Enter correct URL! (e.g., http://........ or https://........)'
        )
        .min(7)
        .required('Please enter website')
})


// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerEditLayer: FC<Props> = ({ className }) => {

    const dispatch = useAppDispatch()

    //--------------------------------------------- 
    const currPersistContent = useAppSelector(state => state.persistor._content.currContent)
    // console.log("currPersistContent in Edit Layer: ",currPersistContent)
    // console.log(currPersistContent.elements[0].sources)

    const currSectId = useAppSelector(state => state.content.currSectionId)
    // console.log('currSectId:',currSectId)

    const currLayerId = useAppSelector(state => state.content.currLayerId)
    // console.log('currLayerId:',currLayerId)

    const [selectedLayer, setSelectedLayer] = useState<Source>()
    // console.log('selectedLayer outside:',selectedLayer)
    // console.log("...selectedLayer", {...selectedLayer})

    // const [isDeleted, setIsDeleted] = useState(false);
    // const currentFolder  = useAppSelector(state => state.editor.currentFolder)  
    // const [updateFolder] = useUpdateFolderMutation();
    // const [deleteFolder] = useDeleteFolderMutation();

    // const [duration, setDuration] = useState<number>(selectedLayer? (selectedLayer.param_source[0].duration?selectedLayer.param_source[0].duration/1000:0):0)
    const [maxOrder, setMaxOrder] = useState<number>(0)
    // console.log("duration in sec.", duration)
    const [volumeControl, setVolumeControl] = useState(false);
    const [volume, setVolume] = useState<number>()

    //-----------------------------------------    
    const {
        register,
        setValue,
        handleSubmit,
        // control,
        reset,
        watch,
        trigger,
        setError,
        formState: {
            errors,
            isDirty,

            isLoading,
            isValid,
            isSubmitting,
            isSubmitSuccessful

        }
    } = useForm<SourceInput>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...selectedLayer,
            dur: 0,
            url: "http://a.com"
        }
    })
    //---------------------------------------------------- 

    // console.log('watch', watch())
    // console.log('watch', watch(["name", "displayType", "category"]))    
    // console.log("watch param_source:", watch("param_source.0"))


    //---------------------------------------------------- 
    // const handleOrderChange = (inputOrder: number) =>{

    //     if (inputOrder <= 0){
    //         inputOrder = 1
    //     }else if (inputOrder > maxOrder){
    //         inputOrder = maxOrder     
    //     }

    //     reset({
    //         ...selectedLayer,
    //         order: inputOrder,
    //         param_source: [{
    //             ...selectedLayer?.param_source[0],
    //             duration: (selectedLayer? selectedLayer.param_source[0].duration: 0),
    //             url: selectedLayer?.param_source[0].url
    //         }],
    //         // dur: duration,
    //         // url: selectedLayer?.param_source[0].url
    //     })
    // }

    //---------------------------------------------------- 
    const formSubmitHandler: SubmitHandler<SourceInput> = async (layer: SourceInput) => {
        try {
            // console.log('currSectId:',currSectId)
            // console.log('currLayerId:',currLayerId)
            console.log("layer in submit", layer)

            dispatch(setSectionId(currSectId))  //which section it belongs to
            dispatch(setLayerId(currLayerId))  //which section it belongs to
            delete layer["dur"]
            delete layer["url"]
            dispatch(setContentSourceLayer(layer))

            // try to refresh
            dispatch(setLayerId(""))

        } catch (error) {
            Swal.fire(
                'Layer: ' + layer.source_list_id,
                'There is something wrong during the update request!',
                'error'
            )

        }
    }

    // ---------------------------
    useEffect(() => {
        const selectedSect = currPersistContent.elements.find((sect, ind) => sect.element_id === currSectId)
        setMaxOrder(selectedSect ? selectedSect.sources.length : 0)


        const foundSect = currPersistContent.elements.find((sect) => sect.element_id === currSectId)?.sources.find((layer) => layer.source_list_id === currLayerId)
        // console.log('foundSect in useEffect:',foundSect)

        if (currPersistContent && foundSect) {
            const dur = foundSect ? (foundSect.param_source[0].duration ? foundSect.param_source[0].duration / 1000 : 0) : 0
            let sourceType = foundSect.source?.type
            if(sourceType =="video" || sourceType == "audio")
            {
                setVolumeControl(true)
                if(foundSect.param_source[0].volume != null)
                {
                    setVolume(foundSect.param_source[0].volume)
                }else{
                    setVolume(defaultVideoVolume)
                }
            }else{
                setVolumeControl(false)
            }
            setSelectedLayer(foundSect)
            // setDuration(dur)

            reset({
                ...foundSect,
                order: foundSect?.order,
                dur: (foundSect ? (foundSect.param_source[0].duration && foundSect.param_source[0].duration > 0 ? foundSect.param_source[0].duration / 1000 : 0) : 0),
                url: (foundSect ? (foundSect.param_source[0].url ? foundSect.param_source[0].url : "http://a.com") : "http://a.com"),
                param_source: [{
                    ...foundSect?.param_source[0],
                    url: foundSect?.param_source[0].url
                }]
            })
        }

    }, [currLayerId])

  function volumeText (value: number){
    return `${value}`
  }
  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    setVolume(newValue as number);
    setValue("param_source.0.volume", newValue as number)
  };
    // ---------------------------
    // useEffect(() => {   
    //     const tmpUrl = (selectedLayer? (selectedLayer.param_source[0].url? selectedLayer.param_source[0].url:""):"")      
    //     reset({
    //         ...selectedLayer,
    //         order: selectedLayer?.order,
    //         url: tmpUrl,
    //         param_source: [{
    //             ...selectedLayer?.param_source[0],
    //             duration: (duration * 1000),                
    //         }],

    //     })
    //     // setPathUrl(tmpUrl)
    //     // console.log("selectLayer in useEffect: ", selectedLayer)
    //     // console.log("param_source in useEffect: ",watch(["param_source"]))
    // }, [ duration, reset ])    


    //----------------------------------------------------
    return (
        <div
            id="kt_drawer_edit_layer"
            data-kt-drawer-toggle={`#kt_drawer_edit_layer_button`}
            data-kt-drawer-close={`#kt_drawer_edit_layer_close`}

            tabIndex={-2}
            className={`offcanvas offcanvas-end bg-white ${className}`}
            // data-kt-drawer="true"
            data-kt-drawer-activate="true"
            data-kt-drawer-overlay="true"
            // data-bs-backdrop="static" 
            aria-labelledby="staticBackdropLabel"
            data-kt-drawer-width="{default:'300px', 'md': '500px'}"

            onMouseOut={() => {
                // setIsMuted(false)
                // setTimeout(() => {
                //     setIsMuted(true)                
                // }, 100);
                let vid = document.getElementById("videoLayer") as HTMLVideoElement;
                if (vid) {
                    vid.pause();
                    // vid.currentTime = 0;
                    vid.muted = true;
                }
            }}
        >
            <div className="offcanvas-header bg-gradient-primary">
                <h5 className="offcanvas-title text-white" id="staticBackdropLabel">
                    <KTSVG
                        path={`/media/icons/duotune/art/art005.svg`}
                        className={`svg-icon-2 svg-icon-white me-2 mt-n2 d-inline`}
                    />
                    Edit Layer
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                </button>
            </div>
            <div className="offcanvas-body text-start">
                <div>
                    {/* for img and video */}
                    {selectedLayer && selectedLayer.source &&
                        <div>
                            {
                                {
                                    "image": <img src={selectedLayer.source.url} className='w-100' />,
                                    "video": <video key={selectedLayer.source.url}
                                        id="videoLayer"
                                        autoPlay={true}
                                        muted={true}
                                        controls
                                        className='w-100 h-100'>
                                        <source src={selectedLayer.source.url} type="video/mp4" />
                                    </video>,
                                    "audio": <audio  playsInline={true} controls autoPlay={false} src={`${selectedLayer.source.url}`} />,
                                    "pdf": <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-3x ' />
                                    /*<PdfViewer url='https://superbolts3-public.s3.ap-southeast-2.amazonaws.com/dev/data/sources/e4dd0ed8abeab60d2e583bf96dae5d75.pdf' />*/
                                }[selectedLayer.source.type]
                            }
                            <span className='fs-7 text-capitalize d-block'>{selectedLayer?.source?.name}</span>
                        </div>
                    }

                    {/* for web */}
                    {/* {selectedLayer && selectedLayer.param_source && selectedLayer.param_source[0].url && selectedLayer.param_source[0].url !== "" &&                            
                            <div>
                                <iframe src={selectedLayer.param_source[0].url} className='w-100 h-450px' scrolling='no'></iframe>
                                <span className='badge badge-light fs-7 ms-2'>
                                    <i className="bi bi-globe text-primary fs-7 me-3"></i>
                                    {selectedLayer.param_source[0].url}
                                </span>
                            </div>
                        } */}

                    {/* for text */}

                </div>



                <form id="frm-edit-layer" className='form mt-5'
                    onSubmit={handleSubmit(formSubmitHandler)}
                >
                    {selectedLayer && selectedLayer.param_source && <>
                        <div className="">
                            <TextField
                                {...register(`url`)}
                                className='mb-3'
                                type="string"
                                label="URL"
                                fullWidth
                                hidden={!selectedLayer.param_source[0].url}
                                margin='dense'
                                variant='outlined'
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setValue("url", e.target.value)
                                    setValue("param_source.0.url", e.target.value)
                                    trigger("url")
                                }}
                                error={!!errors.url}
                                helperText={`${errors.url?.message ?? ''}`}
                            />
                        </div>
                    </>}
                    <div className="mb-3">
                        <TextField
                            {...register("dur")}
                            type="number"
                            label={`Duration ${selectedLayer && selectedLayer.source && selectedLayer.source.type == "pdf" ? 'per page ' : ' '}(sec) `}
                            fullWidth
                            hidden={selectedLayer && selectedLayer.source &&
                                ["image", "pdf"].includes(selectedLayer.source.type)
                                ? false : true}
                            margin='dense'
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setValue("dur", parseInt(e.target.value))
                                setValue("param_source.0.duration", parseInt(e.target.value) * 1000)
                                trigger("dur")
                            }}
                            error={!!errors.dur}
                            helperText={`${errors.dur?.message ?? ''}`}
                        // focused={watch(`param_source[0][duration]`) ? true:false}                                    
                        />
                    </div>

                    {/* {selectedLayer && selectedLayer.source && 
                        ["image", "video"].includes(selectedLayer.source.type) && <>
                            
                            <div className="mb-3">
                                <TextField 
                                    {...register("order")}                                     
                                    type="number"
                                    label="Order" 
                                    fullWidth 
                                    margin='dense'
                                    variant='outlined'                                     
                                    hidden={true}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => handleOrderChange(parseInt(e.target.value))}
                                    error={!! errors.order}
                                    helperText={`${errors.order?.message ?? `Min: 1, Max: ${maxOrder}`}`}
                                    disabled = {maxOrder === 1? true: false}
                                />
                            </div>
                        </>} */}
                    {
                        volumeControl && 
                        <div className='mb-3'>
                            <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
                            <VolumeDown />
                            <Slider 
                                aria-label="Volume" 
                                getAriaValueText={volumeText}
                                value={volume} 
                                onChange={handleVolumeChange} 
                                step={1}
                                marks
                                min={minimumVideoVolume}
                                max={maximumVideoVolume}
                                valueLabelDisplay="auto"
                            />
                            <VolumeUp />
                            </Stack>
                        </div>
                    }
                    <div className="pt-8 text-end row">
                        <div className='col-12 text-end'>
                            <button type="submit" className="btn btn-primary w-100"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                disabled={isSubmitting || !isValid}
                            >
                                {/* <i className="fa fa-save fs-3 me-1 mt-n1"></i> */}
                                Apply Change
                            </button>
                        </div>

                    </div>
                    {/*
                        <button type="button" 
                            className="btn btn-icon btn-light position-absolute bottom-0 end-0 me-7 mb-8" 
                            data-bs-dismiss="offcanvas"
                            data-kt-drawer-dismiss="true"
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr001.svg'
                                className='svg-icon svg-icon-1x'
                            />
                        </button>
                        */}
                </form>

            </div>
        </div>
    )
}

export { DrawerEditLayer }